import { Tag } from "antd";

// btnText is optional, default is 'Info'

//DASHBOARD CHARTS
export const DASHBOARD_IAC_COVERAGE_PERCENTAGE = {
    // btnText: '',
    title: 'Infrastructure as Code Coverage',
    description: `The percentage of your assets that are being managed by IaC tools.\nCalculated by  managed resources divided by total resources`,
    isIcon: true,
    learnMoreLinks: []
}

export const DASHBOARD_UNMANAGED_RESOURCES = {
    // btnText: '',
    title: 'Unmanaged Resources',
    description: 'The number of unmanaged resources for this selected account including total cost.',
    isIcon: true,
    learnMoreLinks: [],
}

export const DASHBOARD_ORGANIZATION_UNMANAGED_RESOURCES = {
    // btnText: '',
    title: 'Unmanaged Resources',
    description: 'The number of unmanaged resources for this selected cloud account including total cost.',
    isIcon: true,
    learnMoreLinks: [],
}

export const AZURE_DASHBOARD_UNMANAGED_RESOURCES = {
    title: 'Unmanaged Resources',
    description: 'The number of unmanaged resources for this selected subscription including total cost.',
    isIcon: true,
    learnMoreLinks: [],
}

export const GCP_DASHBOARD_UNMANAGED_RESOURCES = {
    title: 'Unmanaged Resources',
    description: 'The number of unmanaged resources for this selected project including total cost.',
    isIcon: true,
    learnMoreLinks: [],
}

export const DASHBOARD_DRIFTS = {
    // btnText: '',
    title: 'Stacks with drifts',
    description: 'The number of drifts that have been identified for this selected account.',
    isIcon: true,
    learnMoreLinks: [],
}

export const DASHBOARD_ORGANIZATION_DRIFTS = {
    // btnText: '',
    title: 'Stacks with drifts',
    description: 'The number of drifts that have been identified for this selected cloud account.',
    isIcon: true,
    learnMoreLinks: [],
}

export const AZURE_DASHBOARD_DRIFTS = {
    // btnText: '',
    title: 'Stacks with drifts',
    description: 'The number of drifts that have been identified for this selected subscription.',
    isIcon: true,
    learnMoreLinks: [],
}

export const GCP_DASHBOARD_DRIFTS = {
    // btnText: '',
    title: 'Stacks with drifts',
    description: 'The number of drifts that have been identified for this selected project.',
    isIcon: true,
    learnMoreLinks: [],
}

export const DASHBOARD_IAC_COVERAGE_BY_REGION = {
    // btnText: '',
    title: 'IaC Coverage by Region',
    description: 'For each AWS region, you can view the total number of resources and the corresponding count of resources managed by Infrastructure as Code (IaC).',
    isIcon: true,
    learnMoreLinks: [],
}

export const AZURE_DASHBOARD_IAC_COVERAGE_BY_REGION = {
    // btnText: '',
    title: 'IaC Coverage by Region',
    description: 'For each Azure region, you can view the total number of resources and the corresponding count of resources managed by Infrastructure as Code (IaC).',
    isIcon: true,
    learnMoreLinks: [],
}

export const GCP_DASHBOARD_IAC_COVERAGE_BY_REGION = {
    // btnText: '',
    title: 'IaC Coverage by Region',
    description: 'For each GCP region, you can view the total number of resources and the corresponding count of resources managed by Infrastructure as Code (IaC).',
    isIcon: true,
    learnMoreLinks: [],
}

export const DASHBOARD_TOP_AWS_SERVICES = {
    btnText: '',
    title: '',
    description: ' ',
    isIcon: false,
    learnMoreLinks: [],
}

export const DASHBOARD_IAC_COVERAGE_OVER_TIME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const DASHBOARD_TOP_IAC_COVERAGE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const DASHBOARD_IAC_DISTRIBUTION = {
    title: 'IaC Distribution',
    description: (<>
        <div>This chart represents your resources distribution across different IaC tools.</div>
        <br />
        <div>
            <ul>
                <li>
                    <span className="font-weight-semibold">Unmanaged</span> - Unmanaged resources
                </li>
                <li>
                    <span className="font-weight-semibold">Terraform</span> - Resources managed by Terraform
                </li>
                <li>
                    <span className="font-weight-semibold">Terraform (CM)</span> - Resources managed by Terraform with corresponding ControlMonkey stack
                </li>
                <li>
                    <span className="font-weight-semibold">Terragrunt</span> - Resources managed by Terragrunt
                </li>
                <li>
                    <span className="font-weight-semibold">Terragrunt (CM)</span> - Resources managed by Terragrunt with corresponding ControlMonkey stack
                </li>
                <li>
                    <span className="font-weight-semibold">Opentofu (CM)</span> - Resources managed by Opentofu with corresponding ControlMonkey stack
                </li>
                <li>
                    <span className="font-weight-semibold">CFN</span> - Resources managed by AWS CloudFormation
                </li>
                <li>
                    <span className="font-weight-semibold">By Tag</span> - Resources managed by configured AWS Tags
                </li>
            </ul>
        </div>
    </>),
    isIcon: true,
    learnMoreLinks: [],
}

//CREATION WIZARDS
//WIZARD - stack
export const CREATE_STACK_WIZARD = {
    // btnText: '',
    title: 'Create new Stack',
    description: 'A Stack is a collection of cloud resources that you can manage as a single unit combined with the Terraform code managing/representing the resources, current state of the managed infrastructure, and other configuration like variables.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/stack',
            text: 'Stacks'
        }
    ],
}

export const STACK_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_WIZARD_GENERAL_TAB_TYPE = {
    // btnText: '',
    title: 'IaC Type',
    description: 'The Infrastructure as Code framework that will be used to plan/apply your stack',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/supported-iac-frameworks',
            text: 'Supported IaC Types'
        }
    ],
}

export const STACK_WIZARD_GENERAL_TAB_TYPE_RUN_ALL = {
    btnText: '',
    title: 'Terragrunt run-all',
    description: <>If the “Use Terragrunt run-all commands” is checked, then this stack will execute run-all commands on multiple modules for init/plan/apply.<br /><br /><span className="font-weight-semibold">This configuration is not intended for regular stack usage.</span></>,
    isIcon: true,
    learnMoreLinks: [],
}

export const STACK_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_WIZARD_GENERAL_TAB_DEPLOYMENT_BEHAVIOR = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_WIZARD_GENERAL_TAB_VAR_FILES = {
    title: 'Var Files',
    description: <>Add here custom variable files to pass on to Terraform.<br /><br />The values can be relative paths such as <Tag color="gray" className="px-1 m-1">customVarFile.tfvars</Tag> and <Tag color="gray" className="px-1 m-1">../tfVars/customVarFile.tfvars</Tag> or absolute paths from the repo’s main directory starting with <Tag color="gray" className="px-1 m-1">/</Tag>.<br />For example: <Tag color="gray" className="px-1 m-1">/myVars/customVarFile.tfvars</Tag><br /><br />The following files are automatically loaded and should not be specified:<br /> &#x2022; Files named exactly <Tag color="gray" className="px-1 m-1">terraform.tfvars</Tag> or <Tag color="gray" className="px-1 m-1">terraform.tfvars.json</Tag><br /> &#x2022; Any files with names ending in <Tag color="gray" className="px-1 m-1">.auto.tfvars</Tag> or <Tag color="gray" className="px-1 m-1">.auto.tfvars.json</Tag></>,
    isIcon: true,
    learnMoreLinks: [],
}

export const CUSTOM_TF_VERSION_SELECTION = {
    title: 'Use custom Terraform Version',
    description: 'By default ControlMonkey manages Terraform version.\n\nChecking the "Use custom Terraform version" allows you to select custom Terraform version or selecting the latest release of a specific version.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CUSTOM_TG_VERSION_SELECTION = {
    title: 'Use custom Terragrunt Version',
    description: 'By default ControlMonkey manages Terragrunt version.\n\nChecking the "Use custom Terragrunt version" allows you to select custom Terragrunt version or selecting the latest release of a specific version.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CUSTOM_OT_VERSION_SELECTION = {
    title: 'Use custom OpenTofu Version',
    description: 'By default ControlMonkey manages OpenTofu version.\n\nChecking the "Use custom OpenTofu version" allows you to select custom OpenTofu version or selecting the latest release of a specific version.',
    isIcon: true,
    learnMoreLinks: [],
}

export const STACK_WIZARD_GENERAL_TAB_AUTO_SYNC_DEPLOY_WHEN_DRIFT_DETECTED = {
    title: 'Auto sync',
    description: 'If "turned on", then a deployment will trigger automatically when a drift is detected.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/stack/stack-settings#auto-sync',
            text: 'Auto sync documentation'
        }
    ],
}

export const CUSTOM_RUNNER_CONFIG = {
    title: 'Custom Runner Configuration',
    description: 'By default, ControlMonkey uses managed runners hosted and operated by us.\n Use this option to configure a custom runner for your workloads',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/private-runners',
            text: 'Private Runners documentation'
        },
    ],
}

export const CUSTOM_RUNNER_CONFIG_ALLOW_OVERRIDE = {
    title: 'Allow Override',
    description: 'By setting this option, you allow this configuration to be overridden in specific namespaces/stacks',
    isIcon: true,
    learnMoreLinks: [],
}

export const CUSTOM_RUNNER_CONFIG_ALLOW_OVERRIDE_STACKS = {
    title: 'Allow Override',
    description: 'By setting this option, you allow this configuration to be overridden in specific stacks',
    isIcon: true,
    learnMoreLinks: [],
}

export const ORG_SETTINGS_GENERAL_AWS_TAGS = {
    title: 'Suppressed Resources by AWS Tags',
    description: 'Any AWS resource with the configured tags, will be considered as managed.\nThe tag key/value definition is case sensitive, and supports regex.\n\nExample:\nSecurity Groups that are provisioned by Kubernetes, will have the tag kubernetes.io/cluster/<clusterName>: owned.\nIf you wish to consider them as managed, you can configure -\nkey: kubernetes.io/cluster/.*\nvalue: owned',
    isIcon: true,
    learnMoreLinks: [],
}

export const ORG_SETTINGS_GENERAL_S3_STATE_FILES_LOCATIONS = {
    title: 'State file locations to scan',
    description: 'The S3 buckets of your current terraform state files. This will be used by ControlMonkey to scan for existing managed resources',
    isIcon: true,
    learnMoreLinks: [],
}

export const ORG_SETTINGS_GENERAL_WEEKLY_EMAIL_REPORT_ENABLE = {
    title: 'Enable Weekly Email Report',
    description: 'You can toggle the weekly email report on or off. When it\'s enabled, only the recipients you specify will receive the ControlMonkey weekly email report.',
    isIcon: true,
    learnMoreLinks: [],
}

export const ORG_SETTINGS_GENERAL_WEEKLY_EMAIL_REPORT_ALL_ADMINS = {
    title: 'All Admins',
    description: 'If you select "All Admins," the weekly email report will be sent to every administrator within your organization.',
    isIcon: true,
    learnMoreLinks: [],
}

export const ORG_SETTINGS_GENERAL_WEEKLY_EMAIL_REPORT_EMAIL_ADDRESSES = {
    title: 'Email Addresses to include',
    description: 'You have the option to include members of your organization to receive the weekly email report either by choosing their names from the dropdown list or by manually entering their email addresses.',
    isIcon: true,
    learnMoreLinks: [],
}

export const ORG_SETTINGS_GENERAL_WEEKLY_EMAIL_REPORT_EMAIL_ADDRESSES_TO_EXCLUDE = {
    title: 'Email Addresses to exclude',
    description: <span>You have the option to <span className="font-weight-semibold">exclude</span> members of your organization from receiving the weekly email report either by choosing their names from the dropdown list or by manually entering their email addresses.</span>,
    isIcon: true,
    learnMoreLinks: [],
}

export const STACK_WIZARD_VCS_TAB = {
    title: 'VCS Provider',
    description: 'In this tab you should choose the location of the Terraform code within your VCS provider.\nThe stack will pick up any push/merge events on the path you are defining and plan/apply accordingly',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system',
            text: 'Supported version control systems'
        },
        {
            url: 'https://docs.controlmonkey.io/on-boarding/how-to-connect-your-version-control-system',
            text: 'How to connect your version control system to ControlMonkey'
        }
    ],
}

export const STACK_WIZARD_VCS_TAB_PROVIDER = {
    title: 'VCS Provider',
    description: 'Choose the version control provider for your stack.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system',
            text: 'Supported version control systems'
        },
        {
            url: 'https://docs.controlmonkey.io/on-boarding/how-to-connect-your-version-control-system',
            text: 'How to connect your version control system to ControlMonkey'
        }
    ],
}

export const STACK_WIZARD_VCS_TAB_REPOSITORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_WIZARD_VCS_TAB_SUB_DIRECTORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_WIZARD_VCS_TAB_BRANCH = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_WIZARD_VCS_TAB_GLOB_PATTERNS = {
    title: 'Trigger Patterns',
    description: 'On enabling “Include Sub Directories”, Every change in a sub directory file(s) will also trigger a stack run.\n\nYou can also use glob patterns to include/exclude paths that should trigger a stack run.',
    isIcon: true,
    learnMoreLinks: [{
        url: 'https://docs.controlmonkey.io/main-concepts/stack/stack-settings#configuring-trigger-patterns',
        text: 'How to configure Trigger Patterns'
    }],
}

export const STACK_WIZARD_VARIABLES_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}
//WIZARD - stack auto discovery
export const CREATE_STACK_DISCOVERY_WIZARD = {
    // btnText: '',
    title: 'Stack Auto Discovery',
    description: 'Automatically create stacks by opening a pull request based on patterns.\nYou can configure path patterns in your repos which will automatically create a stack and start a plan in case a pull request is opened.',
    isIcon: true,
    // learnMoreLinks: [
    //     {
    //         url: '',
    //         text: ''
    //     }
    // ],
}

export const STACK_DISCOVERY_WIZARD_VCS_PATTERN = {
    // btnText: '',
    title: 'Stack Auto Discovery - VCS Patterns',
    description: 'In this tab you configure the path patterns in your repos that should create a stack.\nOnce a PR is opened in a matching path pattern, a stack will be created and a plan will start.',
    isIcon: true,
    // learnMoreLinks: [
    //     {
    //         url: '',
    //         text: ''
    //     }
    // ],
}

export const STACK_DISCOVERY_WIZARD_VCS_PATTERN_PATHS = {
    // btnText: '',
    title: 'Stack Auto Discovery - Path Pattern',
    description: (
        <>
          Here you can configure <a href="https://en.wikipedia.org/wiki/Glob_(programming)" target="_blank" rel="noopener noreferrer">glob patterns</a> to look for a PR under specific VCS patterns. You can configure multiple path patterns.<br /><br />
          Path patterns must start with '*/' or '**/', contain '/*/' or '/**/', or end with '/**'. Avoid starting or ending the pattern with '/'.
        </>
      ),

    isIcon: true,
    // learnMoreLinks: [
    //     {
    //         url: '',
    //         text: ''
    //     }
    // ],
}

export const STACK_DISCOVERY_WIZARD_VCS_PATTERN_ADD_ADDTIONAL_VCS_PATTERN = {
    // btnText: '',
    title: 'Stack Auto Discovery - Additional VCS Patterns',
    description: 'In some cases you would like to configure multiple patterns in multiple repos, for this cases you can configure another block of VCS Pattern.',
    isIcon: true,
    // learnMoreLinks: [
    //     {
    //         url: '',
    //         text: ''
    //     }
    // ],
}

export const STACK_DISCOVERY_WIZARD_STACK_CONFIGURATION = {
    // btnText: '',
    title: 'Stack Auto Discovery - Stack Configuration',
    description: 'Once a PR is matched by the patterns, a stack will be created.\nHere you configure the stack properties and the namespace where the stack will be created.',
    isIcon: true,
    // learnMoreLinks: [
    //     {
    //         url: '',
    //         text: ''
    //     }
    // ],
}

//WIZARD - namespace
export const CREATE_NAMESPACE_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const NAMESPACE_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const NAMESPACE_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const NAMESPACE_WIZARD_GENERAL_TAB_CREDENTIALS_AWS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const NAMESPACE_WIZARD_GENERAL_TAB_CREDENTIALS_AWS_ADVANCED = {
    btnText: '',
    title: 'Advanced AWS Credentials',
    description: 'You can use the advanced mode to set up multiple credentials with AWS Profiles.\nAWS Credentials without a profile name will be used as default credentials.',
    isIcon: true,
    learnMoreLinks: [],
}

export const NAMESPACE_WIZARD_GENERAL_TAB_CREDENTIALS_GCP = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const NAMESPACE_WIZARD_GENERAL_TAB_CREDENTIALS_AZURE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const APPROVAL_POLICY_SELECTION_STACK = {
    title: 'Set approval policy',
    description: 'Setting approval policy will introduces a manual approval step before the \'Terraform Apply\' command actually runs.\nBy default, Every deployment requires a manual approval unless configured on the stack or namespace level.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/stack/stack-settings#set-approval-policy',
            text: 'Stack approval policy settings'
        },
    ],
}

export const APPROVAL_POLICY_SELECTION_NAMESPACE = {
    title: 'Set approval policy',
    description: 'Setting approval policy will introduces a manual approval step before the \'Terraform Apply\' command actually runs.\nBy default, Every deployment requires a manual approval.\nOverride behavior will determine if this configuration can be override by stacks.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/namespaces/namespace-settings#set-approval-policy',
            text: 'Namespace approval policy settings'
        },
    ],
}

export const APPROVAL_POLICY_SELECTION_IMPORT_STACK_WIZARD = {
    ...APPROVAL_POLICY_SELECTION_STACK
}

export const APPROVAL_POLICY_SELECTION_IMPORT_BULK_STACKS_WIZARD = {
    ...APPROVAL_POLICY_SELECTION_STACK
}

export const APPROVAL_POLICY_SELECTION_BLUEPRINT_WIZARD = {
    ...APPROVAL_POLICY_SELECTION_STACK
}

export const APPROVAL_POLICY_REQUIRE_TEAM_APPROVAL_OPTION = {
    title: 'Require Teams Approval',
    description: 'This rule type allows you to specify the teams necessary for deployment approval. At least one member of each selected team must approve the deployment for it to proceed.\n\nNote: If the policy includes multiple teams, such as Team1 and Team2, and a user is a member of both, their approval will apply to both teams.',
    isIcon: true,
    learnMoreLinks: [],
}

//WIZARD - template
export const CREATE_TEMPLATE_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_GENERAL_TAB_TYPE = {
    ...STACK_WIZARD_GENERAL_TAB_TYPE
}

export const TEMPLATE_WIZARD_GENERAL_TAB_TYPE_RUN_ALL = {
    ...STACK_WIZARD_GENERAL_TAB_TYPE_RUN_ALL
}

export const TEMPLATE_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_GENERAL_TAB_TTL_POLICY = {
    btnText: '',
    title: 'TTL Policy',
    description: 'Set TTL (Time to live) Policy when creating a template. Adding/Updating this value does not have any effect on already running stacks.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_GENERAL_TAB_SETTINGS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_GENERAL_TAB_SETTINGS_SKIP_STATE_REFRESH_ON_DESTORY = {
    btnText: '',
    title: 'Skip state refresh on destroy',
    description: 'If set, the plan won\'t refresh on destroy. This flag is equivalent to set "-refresh=false" on destroy command. Can be used when data sources are expected to be deleted from outside of the terraform code.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_VCS_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_VCS_TAB_PROVIDER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_VCS_TAB_REPOSITORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_VCS_TAB_SUB_DIRECTORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_VCS_TAB_BRANCH = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_VARIABLES_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_NAMESPACES_TAB_NAMESPACES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TEMPLATE_WIZARD_NAMESPACES_TAB_NAMESPACES_MAPPING = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//WIZARD - blueprint template
export const CREATE_BLUEPRINT_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_GENERAL_TAB_TYPE = {
    ...STACK_WIZARD_GENERAL_TAB_TYPE
}

export const BLUEPRINT_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_GENERAL_TAB_SETTINGS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_GENERAL_TAB_SETTINGS_SKIP_PLAN_ON_STACK_INIT = {
    btnText: '',
    title: 'Skip Plan On Stack Initialization',
    description: 'If set, an automatic plan will not be triggered on PR.',
    isIcon: true,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_GENERAL_TAB_SETTINGS_AUTO_APPROVE_APPLY_ON_INIT = {
    btnText: '',
    title: 'Auto Approve Apply On Initialization',
    description: 'If set, once the PR has been merged and the Deployment will start, an Apply will automatically run without waiting for approval. This is relevant only for the first initialization deployment of the created stack.',
    isIcon: true,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_VCS_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_VCS_TAB_PROVIDER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_VCS_TAB_REPOSITORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_VCS_TAB_BLUEPRINT_SUB_DIRECTORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_VCS_TAB_BLUEPRINT_BRANCH = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_STACK_CONFIGURATION_TAB_STACK_LOCATION_PATH_PATTERN = {
    title: 'Sub-Directory',
    description: 'The path of the stack should include at least one dynamic parameter called a "Substitute Parameter" which will be displayed as a substitute parameter. These dynamic parameters are in the form of {some_parameter}, e.g., {stack_path}.\nWhen launching a stack from a persistent template, substitute parameters will be replaced with values provided by the user.\n\nExample for Sub-Directory:\ndev/{username}/from-template/{stack_name}',
    isIcon: true,
    learnMoreLinks: [{
        url: 'https://docs.controlmonkey.io/main-concepts/self-service-templates/template-for-persistent-stack/creating-template-for-persistent-stack',
        text: 'Creating Template for Persistent Stack'
    }],
}

export const BLUEPRINT_WIZARD_STACK_CONFIGURATION_TAB_STACK_LOCATION_BRANCH = {
    title: 'Stack Branch',
    description: 'The branch of the stack is optional and can also include one or more dynamic parameters called a "Substitute Parameters" which will be displayed as a substitute parameter. These dynamic parameters are in the form of {some_parameter}, e.g., {stack_branch}.\nWhen launching a stack from a persistent template, substitute parameters will be replaced with values provided by the user.\n\nExample for Branch: development-{team}',
    isIcon: true,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_STACK_CONFIGURATION_TAB_STACK_NAME = {
    title: 'Stack Name',
    description: 'The stack name should include at least one dynamic parameter called a "Substitute Parameter" which will be displayed as a substitute parameter. These dynamic parameters are in the form of {some_parameter}, e.g., {stack_name}.\nWhen launching a stack from a persistent template, substitute parameters will be replaced with values provided by the user.\n\nExample for Stack Name: {env}-{aws_service}',
    isIcon: true,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_STACK_CONFIGURATION_TAB_VCS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const BLUEPRINT_WIZARD_SUBSTITUTE_PARAMETERS = {
    title: 'Substitute Parameters',
    description: 'Substitute parameters are dynamic parameters provided by the creator of the Persistent Template and filled by the user when launching stacks from the template. These parameters are represented in the Terraform file by placeholders enclosed in curly braces. For example: {replace-me}',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/self-service-templates/template-for-persistent-stack/substitute-parameters',
            text: 'Substitute Parameters documentation'
        }
    ],
}

//WIZARD - stack from blueprint
export const CREATE_STACK_FROM_BLUEPRINT_WIZARD = {
    btnText: '',
    title: 'Create new Persistent Stack from Template',
    description: 'Launch a persistent stack to spin up workloads that need ongoing management and maintenance through Terraform. The Terraform code will be copied to a new branch in the Git repository, and a pull request (PR) will be created. The code changes will only be applied and deployed after authorized users review and merge the PR.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/self-service-templates/template-for-persistent-stack/launching-stack-from-a-template-for-persistent-stack",
            text: "Create new Persistent Stack from Template"
        }
    ],
}


export const STACK_FROM_BLUEPRINT_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_VCS_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_VCS_TAB_PROVIDER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_VCS_TAB_REPOSITORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_VCS_TAB_STACK_LOCATION_PATH_PATTERN = {
    title: 'Sub-Directory',
    description: <>If the template has a specific stack path pattern, defiend by custom <Tag color="blue" className="ml-1">{"<>"}</Tag> placeholder(s) You will need to fill the missing path input(s) to create a valid Sub-Directory for the created stack location.<br /><br />See the documentation for more information.</>,
    isIcon: true,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_VCS_TAB_STACK_LOCATION_BRANCH = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_VARIABLES_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_BLUEPRINT_WIZARD_SUBSTITUTE_PARAMETERS_POPULATE = {
    ...BLUEPRINT_WIZARD_SUBSTITUTE_PARAMETERS
}

// WIZARD - create stack set
export const CREATE_STACK_SET_WIZARD = {
    btnText: '',
    title: 'Connect AWS Organization',
    description: '"Connect AWS organization" will allow you to connect multiple AWS accounts at once and will provide a centralized way to manage policies.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/on-boarding/connecting-cloud-account/how-to-connect-your-aws-organization',
            text: 'How to Connect your AWS Organization'
        }
    ],
}

export const CREATE_STACK_SET_WIZARD_CONFIGURE_TAB_ACCOUNT_ID = {
    title: 'Management Account Id',
    description: 'The id of the AWS management account.\nTo connect an AWS organization to ControlMonkey, you must first connect your management account.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CREATE_STACK_SET_WIZARD_STACKSET_TAB_CREDS_TYPE = {
    title: 'Credentials Type',
    description: 'Choose the appropriate credentials type.\n\nThere are two types of credentials:\n1. Discovery - Read-only permissions. Will be used by ControlMonkey to discover resources in your cloud accounts.\n2. Terraform Executions - This permissions will be used only when ControlMonkey runs Terraform commands when executing Runs.',
    isIcon: true,
    learnMoreLinks: [],
}

//WIZARD - stack from template
export const CREATE_STACK_FROM_TEMPLATE_WIZARD = {
    btnText: '',
    title: 'Create New Ephemeral Stack From Template',
    description: 'Launch an ephemeral stack to temporarily spin up infrastructure for a specified period, such as a few hours or days, without managing it after provisioning.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/self-service-templates/template-for-ephemeral-stack/launching-stack-from-a-template-for-ephemeral-stack",
            text: "Create New Ephemeral Stack From Template"
        }
    ],
}


export const STACK_FROM_TEMPLATE_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_TEMPLATE_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_FROM_TEMPLATE_WIZARD_VARIABLES_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}


//WIZARD - import stack
export const IMPORT_STACK_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_RESOURCES_TAB_RESOURCES_TABLE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_GENERAL_TAB_NAMESPACE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_GENERAL_TAB_IAC_TYPE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_GENERAL_TAB_DEPLOYMENT_BEHAVIOR = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_GENERAL_TAB_RESOURCES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_SOURCE_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_VCS_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_VCS_TAB_PROVIDER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_VCS_TAB_REPOSITORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_VCS_TAB_SUB_DIRECTORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_VCS_TAB_SUB_DIRECTORY_CM_PR = {
    title: 'Sub-directory for PR',
    description: 'When selecting ControlMonkey to open a PR, you need to specify a non-existent sub-directory. ControlMonkey will create this path and will open a PR with the Terraform code files in this path.',
    isIcon: true,
    learnMoreLinks: [],
}

export const IMPORT_STACK_WIZARD_VCS_TAB_BRANCH = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//WIZARD - import existing resources to existing stack
export const IMPORT_RESOURCES_TO_EXISTING_STACK_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_RESOURCES_TO_EXISTING_STACK_WIZARD_STACK_SELECTION_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_RESOURCES_TO_EXISTING_STACK_WIZARD_SOURCE_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_RESOURCES_TO_EXISTING_STACK_WIZARD_IMPORT_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//WIZARD - import bulk stacks wizard
export const IMPORT_BULK_STACKS_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_VCS_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_VCS_TAB_PROVIDER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_VCS_TAB_REPOSITORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_VCS_TAB_BRANCH = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_GENERAL_TAB_DEPLOYMENT_BEHAVIOR = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_GENERAL_TAB_NAMESPACE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_SUMMARY_TAB = {
    title: 'Summary',
    description: 'Here you can configure the stack name and VCS repo path for all the new stacks\n\nDetails:\n"Stack Name": The name of the stack that will be created.\n"VCS Repo Path Prefix": Will be added as a prefix for each path in this bulk import (recommended, not required).\n"VCS Repo Path": The path for each stack code. You will see it with the prefix you insert above. (required).',
    isIcon: true,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_SUMMARY_TAB_REPO_PATH_PREFIX = {
    title: 'VCS Repo Path Prefix',
    description: 'A prefix for all of the stack paths in this bulk import.',
    isIcon: true,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_SUMMARY_TAB_STACK_NAME = {
    title: 'Stack Name',
    description: 'The name of the stack that will be created.',
    isIcon: true,
    learnMoreLinks: [],
}

export const IMPORT_BULK_STACKS_WIZARD_SUMMARY_TAB_REPO_PATH = {
    title: 'VCS Repo Path',
    description: 'The full path in the VCS for the stack code.',
    isIcon: true,
    learnMoreLinks: [],
}

//WIZARD - variable
export const CREATE_VARIABLE_WIZARD = {
    title: 'Variables',
    description: 'Using infrastructure as code (IaC) allows you to customize certain aspects of a configuration for a specific deployment using variables, without having to change the code itself. This means that a single configuration can be used in different contexts to create different deployments. Examples of variables include ports, IP addresses, cloud credentials, availability zones, and images.',
    isIcon: true,
    learnMoreLinks: [{
        url: 'https://docs.controlmonkey.io/main-concepts/variables',
        text: 'Variables'
    }],
}

export const EDIT_VARIABLE_WIZARD = {
    title: 'Variables',
    description: 'Using infrastructure as code (IaC) allows you to customize certain aspects of a configuration for a specific deployment using variables, without having to change the code itself. This means that a single configuration can be used in different contexts to create different deployments. Examples of variables include ports, IP addresses, cloud credentials, availability zones, and images.',
    isIcon: true,
    learnMoreLinks: [{
        url: 'https://docs.controlmonkey.io/main-concepts/variables',
        text: 'Variables'
    }],
}

export const VARIABLE_WIZARD_TYPE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const VARIABLE_WIZARD_KEY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const VARIABLE_WIZARD_VALUE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const VARIABLE_WIZARD_CONDITIONS = {
    title: 'Variable Conditions',
    description: 'Variable conditions will enforce the values provided by a user for those variables.\nSome examples are using conditions to restrict instance count to be lower than a certain number or define a regex restriction for your variable values.',
    isIcon: true,
    learnMoreLinks: [{
        url: 'https://docs.controlmonkey.io/main-concepts/variables/variable-conditions',
        text: 'Variable Conditions'
    }],
}

export const VARIABLE_WIZARD_IS_SENSITIVE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const VARIABLE_WIZARD_IS_OVERRIDABLE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const VARIABLE_WIZARD_IS_REQUIERED = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const VARIABLE_WIZARD_DISPLAY_NAME = {
    title: 'Display Name',
    description: 'The variable key must remain exactly as it is in the code. The \'Display Name\' property, an optional attribute, provides the flexibility to assign a descriptive name to the variable.\nThis can be useful especially for self service variables to make the variables more user-friendly.',
    isIcon: true,
    learnMoreLinks: [],
}

export const VARIABLE_WIZARD_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//WIZARD - control policy
export const CREATE_CONTROL_POLICY_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TAGS_KEY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TAGS_ALLOWED_VALUE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TAGS_RESOURCE_TYPES = {
    btnText: '',
    title: 'AWS required tags - Resource types',
    description: 'You can add resource types to this policy. if set, the required tags policy will only be enforced on the selected resource types.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_ALLOWED_REGIONS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}


export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_DENIED_REGIONS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_ALLOWED_RESOURCE_TYPES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_DENIED_RESOURCE_TYPES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_ALLOWED_IN_REGIONS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}


export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_DENIED_IN_REGIONS = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_RESOURCE_TYPE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_ALLOWED = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_DENIED = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_ENFORCEMENT_LEVEL = {
    title: 'Enforcement Level',
    description: 'When mapping a policy to a Namespace/Stack you should set the enforcement level of the policy on that Namespace/stack.\nBy selecting the appropriate enforcement level, you can establish the desired level of control and response when policy violations occur within your infrastructure.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/control-policies/enforcement-level",
            text: "Control Policies - Enforcement Level"
        }
    ],
}

export const CONTROL_POLICY_WIZARD_NAMESPACES_TAB_NAMESPACES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_NAMESPACES_TAB_NAMESPACES_MAPPING = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_DENY_STACK_COST_ENFORCE_COST_INCREASE_BY_PERCENTAGE_CHECKBOX = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_DENY_STACK_COST_ENFORCE_MONTHLCOST_INCREASE_BY_PERCENTAGE_CHECKBOX = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_DENY_STACK_COST_THRESHOLD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_DENY_STACK_COST_MIN_MONTHLY_COST = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_DENY_STACK_COST_MONTHLY_COST_DIFF_THRESHOLD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_DENY_RESOURCE_DELETION_RESOURCE_TYPE = {
    title: 'Deny Resource Deletion - Resource types',
    description: 'You can optionally add resource types to this policy. If set, the deny resource deletion policy will only be enforced on the resource types you specify.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_READ_ONLY_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_MAPPING_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}


//WIZARD - control policy group
export const CREATE_CONTROL_POLICY_GROUP_WIZARD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_CONTROL_POLICY_GROUP_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_CONTROL_POLICY_GROUP_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_CONTROL_POLICY_GROUP_WIZARD_GENERAL_TAB_SEVERITY = {
    title: 'Severity',
    description: 'When defining a control policy group, each policy within the group can specify a \'Severity\' property. Its purpose is to dynamically adjust the enforcement level based on the severity of a policy failure.\n\nNote - This property becomes effective only when mapping a control policy group to a target and the \'Enforcement Level\' property of the mapping is set to \'By Severity\'.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_RESOURCE_PROPERTY_CONDITION = {
    title: 'TF Resource Property Condition',
    description: 'With this policy, you have the ability to configure a customized policy that verifies various conditions on the resource properties.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/control-policies/customer-managed-policies/tf-resource-property-condition-policy',
            text: 'TF Resource Property Condition'
        }
    ],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_RESTRICTED_MODULE_VERSIONS = {
    title: 'Terraform Restricted Module Versions',
    description: 'With this policy, you can restrict the versions that are allowed to be used for each module.\nNamespaces/Stacks with this policy, will allow the specified modules, only if they are from one of the allowed versions.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/control-policies/customer-managed-policies/terraform-module-policies#terraform-restricted-module-versions',
            text: 'Terraform Restricted Module Versions'
        }
    ],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_DENIED_MODULES = {
    title: 'Terraform Denied Modules',
    description: 'With this policy, you can deny usage of modules.\nNamespaces/Stacks with this policy, will deny any module that match one of the configuration you provided in the policy.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/control-policies/customer-managed-policies/terraform-module-policies#terraform-denied-modules',
            text: 'Terraform Denied Modules'
        }
    ],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_DENIED_MODULES_LOCAL_MODULES = {
    title: 'Local Modules',
    description: 'Provide a specific path in a specific repo to deny.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_DENIED_MODULES_MODULES = {
    title: 'Modules',
    description: 'Source - the source of the specified module, can be a specific source or a regex.\nVersions - not required, it will deny use of this module with one of the provided versions, or any use of this module if no version provided.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_ALLOWED_MODULES_SOURCES = {
    title: 'Terraform Allowed Module Sources',
    description: 'With this policy, you can restrict the usage of your modules to specified sources only.\nNamespaces/Stacks with this policy, will allow only modules that match one of the configuration you provided in the policy.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/control-policies/customer-managed-policies/terraform-module-policies#terraform-allowed-module-sources',
            text: 'Terraform Allowed Module Sources'
        }
    ],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_ALLOWED_MODULES_SOURCES_ALLOWED_REGISTERIES = {
    title: 'Allowed Registries',
    description: 'You can provide allowed registries, for example "registry.terraform.io" / "registry.opentofu.org" or any other registry, including private registry of your own.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_ALLOWED_MODULES_SOURCES_ALLOWED_GIT_SOURCES = {
    title: 'Allowed Git Sources',
    description: <span>You can configure modules from git sources, that will be allowed regardless to the format used in the code.<br /><br /><ul><li>Domain: can be "github.com" / "bitbucket.org"... </li><li>Repo: can be a specific repo or a regex that will catch multiple paths.</li><li>Clone Types: a list of clone types, allowed values [ssh, http].</li></ul></span>,
    isIcon: true,
    learnMoreLinks: [],
}

export const CONTROL_POLICY_WIZARD_GENERAL_TAB_TYPE_TF_ALLOWED_MODULES_SOURCES_ALLOWED_GENERIC_SOURCES = {
    title: 'Allowed Generic Sources',
    description: 'Use it to allowed sources that are not git or registry (as s3, gcs..), or to specify a format that allowed in the code.\nYou can provide a specific source, or a regex that will catch multiple sources.',
    isIcon: true,
    learnMoreLinks: [],
}

//WiZARD - create TF Module
export const CREATE_TF_MODULE_WIZARD = {
    btnText: '',
    title: 'Create new Terraform Module',
    description: 'Add a module to the ControlMonkey Private registry. The module and its version will be used in Terraform code to access it. Modules can only be used if Git releases or tags are created in the repository. Versioning options include:\n\nMono-Repo – Manage multiple modules in a single repository with separate versions using a Tag Prefix.\n\nModule per Repo – Version the entire repository using Semantic Versioning, without requiring a Tag Prefix.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: 'Name',
    description: 'The module name can include letters, numbers, hyphens (‘-’), and underscores (‘_’), but it cannot start or end with an hyphen or underscore. This name will be used in the Terraform Module source field in the code.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_GENERAL_TAB_PROVIDER_NAME = {
    btnText: '',
    title: 'Provider Name',
    description: 'The provider name can only contain lowercase letters and numbers. It will be used in the Terraform Module source field in the code.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_GENERAL_TAB_TAG_PREFIX = {
    btnText: '',
    title: 'Tag Prefix',
    description: 'The prefix for Git tags used in versioning modules within a mono-repo. Each module can have a separate versioning prefix. For example, a SecurityGroup module with the tag \'sg/1.10.0\' has a Tag Prefix of \'sg\' and the version in the Private Registry will be \'1.10.0\' When using a Tag Prefix, the Git tag format must:\n\n1. Start with the prefix, which can contain letters, numbers, and hyphens (‘-’), with a maximum length of 32 characters.\n\n2. Include a \'/\' between the prefix and version number.\n\n3. Follow semantic versioning (e.g., \'v1.2.3\', \'1.2.3\', \'1.2.3-beta\').',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_GENERAL_TAB_DESCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_VCS_TAB = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_VCS_TAB_PROVIDER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_VCS_TAB_REPOSITORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const TF_MODULE_WIZARD_VCS_TAB_SUB_DIRECTORY = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//WiZARD - edit TF Module
export const EDIT_TF_MODULE_WIZARD = {
    btnText: '',
    title: 'Edit Terraform Module',
    description: 'Edit an existing module in the ControlMonkey Private Registry. Updates to the module, including new versions, will be reflected in the Terraform code. To use the updated module, ensure that Git releases or tags are created in the repository. In a Mono-Repo setup, you can manage multiple modules within a single repository by using a Tag Prefix to distinguish versions. Alternatively, with a Module per Repo setup, you can version the entire repository using Semantic Versioning without the need for a Tag Prefix.',
    isIcon: true,
    learnMoreLinks: [],
}

//WIZARD - model
export const CREATE_MODEL_WIZARD = { // delete in the future when removing old model wizard
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_ACCOUNT = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_SUBSCRIPTION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_PROJECT = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_REGION = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_FORM = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL_RESOURCE_TYPE_FIELD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL_SEARCH_AVAILABLE_RESOURCES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL_AVAILABLE_RESOURCES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL_SELECTED_RESOURCES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_RESOURCES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_RESOURCE_TYPE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_GENERAL_TAB_RESOURCES_FIELD = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

// WIZARD - edit template/blueprint
export const EDIT_TEMPLATE_WIZARD = {
    btnText: '',
    title: 'Template for Ephemeral Stack',
    description: 'Designed for temporary infrastructure needs. This template type spins up infrastructure using Terraform code, but the resources are not managed by the code after provisioning. It\'s ideal for short-lived deployments or testing scenarios where the infrastructure is temporary.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/self-service-templates/template-for-ephemeral-stack",
            text: "Template for Ephemeral Stack"
        }
    ],
}

export const EDIT_BLUEPRINT_WIZARD = {
    btnText: '',
    title: 'Template for Persistent Stack',
    description: 'Designed for long-term infrastructure management. The Terraform code is copied to a new location in the Git repository under a dedicated branch, and a pull request (PR) is opened. This approach is particularly useful for production workloads that require ongoing management and maintenance through Terraform, ensuring that critical infrastructure resources are consistently managed and version-controlled.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/self-service-templates/template-for-persistent-stack",
            text: " Template for Persistent Stack"
        }
    ],
}

//MODALS
export const GENERATED_CODE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const GENERATED_CODE_TF_MODULE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MAP_POLICY_TO_NAMESPACE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MAP_POLICY_TO_STACK_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_NAMESPACE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NOTIFICATION_SUBSCRIPTION_MODAL = {
    btnText: '',
    title: 'Add Subscription',
    description: 'Add Subscription to different event types and get notifications in real time.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/notifications#creating-a-subscription',
            text: 'Creating Notifications in ControlMonkey'
        }
    ],
}

export const ADD_NOTIFICATION_SUBSCRIPTION_MODAL_EVENT_TYPE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NOTIFICATION_SUBSCRIPTION_MODAL_PROTOCOL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NOTIFICATION_SUBSCRIPTION_MODAL_ENDPOINT = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NAMESPACE_PERMISSION_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NAMESPACE_PERMISSION_MODAL_TEAM = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NAMESPACE_PERMISSION_MODAL_ROLE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NAMESPACE_PERMISSION_MODAL_USER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_NAMESPACE_PERMISSION_MODAL_PROGRAMMATIC_USER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_TEMPLATE_PERMISSION_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_TEMPLATE_PERMISSION_MODAL_NAMESPACE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_TTL_POLICY_MODAL = {
    btnText: '',
    title: 'Edit TTL Policy',
    description: 'Set TTL (Time to live) Policy to a namespace. Adding/Updating this value does not have any effect on already running stacks.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CONNECT_AWS_ACCOUNT_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONNECT_AZURE_ACCOUNT_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_TEAM_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_TEAM_MODAL_CUSTOM_IDP_ID = {
    title: 'Custom ID for IDP',
    description: 'If you have groups/teams within your IDP that you want to use their IDs as the ControlMonkey team ID you can do that by configuring a custom ID on the Team.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/single-sign-on/custom-id-for-teams',
            text: 'Custom ID for teams documentation'
        }
    ],
}

export const INVITE_USER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const INVITE_USER_MODAL_ROLE = {
    btnText: '',
    title: 'Invite New User - Role',
    description: 'Select the user\'s role in the organization.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/users-and-roles#organization-roles',
            text: 'Organization User Role'
        }
    ],
}

export const ADD_MEMBER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const ADD_PROGRAMMATIC_MEMBER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CONNECT_GITHUB_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your GitHub provider, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/github',
            text: 'VCS Provider - GitHub'
        }
    ],
}

export const CONNECT_GITHUB_ENTERPRISE_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your GitHub Enterprise Server provider, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/github-enterprise',
            text: 'VCS Provider - GitHub Enterprise Server'
        }
    ],
}

export const CONNECT_GITLAB_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your GitLab provider, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab',
            text: 'VCS Provider - GitLab'
        }
    ],
}


export const CONNECT_GITLAB_ONPREM_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your GitLab CE/EE, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab-onprem',
            text: 'VCS Provider - GitLab CE/EE'
        }
    ],
}

export const CONNECT_GITLAB_MODAL_USER_TOOLTIP = {
    btnText: '',
    title: 'User Identifier',
    description: <><span>This setting should be used when “Push Rules” are configured on git repositories to restrict commits to verified users. This field is required for push capabilities and it's value will be used as the committer Email.</span><br /><br /></>,
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab#advanced',
            text: 'Personal Access Token (PAT) '
        },
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab#advanced-1',
            text: 'Group/Project Token'
        }
    ],
}

export const CONNECT_GITLAB_ON_PREM_MODAL_USER_TOOLTIP = {
    btnText: '',
    title: 'User Identifier',
    description: <><span>This setting should be used when “Push Rules” are configured on git repositories to restrict commits to verified users. This field is required for push capabilities and it's value will be used as the committer Email.</span><br /><br /></>,
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab-onprem#advanced',
            text: 'Personal Access Token (PAT) '
        },
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab-onprem#advanced-1',
            text: 'Group/Project Token'
        }
    ],
}

export const CONNECT_AZURE_DEVOPS_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your Azure DevOps, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/azure-devops',
            text: 'VCS Provider - Azure DevOps'
        }
    ],
}

export const CONNECT_AZURE_DEVOPS_SERVER_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your Azure DevOps Server, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/azure-devops-server',
            text: 'VCS Provider - Azure DevOps Server'
        }
    ],
}

export const CONNECT_BITBUCKET_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your Bitbucket Cloud, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/bitbucket-cloud',
            text: 'VCS Provider - Bitbucket Cloud'
        }
    ],
}

export const CONNECT_BITBUCKET_DATA_CENTER_MODAL = {
    title: 'VCS Provider',
    description: 'To integrate ControlMonkey with your Bitbucket Data Center, simply follow the instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/bitbucket-data-center',
            text: 'VCS Provider - Bitbucket Data Center'
        }
    ],
}

export const EDIT_GITHUB_MODAL = {
    btnText: '',
    title: '',
    description: '',
}

export const EDIT_GITHUB_ENTERPRISE_MODAL = {
    btnText: '',
    title: '',
    description: '',
}

export const EDIT_GITHUB_ENTERPRISE_APPLICATION_MODAL = {
    btnText: '',
    title: '',
    description: '',
}

export const EDIT_GITLAB_MODAL = {
    btnText: '',
    title: '',
    description: '',
}

export const EDIT_GITLAB_ON_PREM_MODAL = {
    btnText: '',
    title: '',
    description: '',
}

export const EDIT_BITBUCKET_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_AZURE_DEVOPS_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_AZURE_DEVOPS_SERVER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const GITLAB_WEBHOOK_MODAL = {
    title: 'VCS Provider Webhooks',
    description: 'To set up Webhooks from your GitLab provider to ControlMonkey, simply follow the installation instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab/gitlab-configuring-webhooks',
            text: 'VCS Provider Webhooks  - GitLab'
        }
    ],
}

export const GITLAB_ONPREM_WEBHOOK_MODAL = {
    title: 'VCS Provider Webhooks',
    description: 'To set up Webhooks from your GitLab CE/EE provider to ControlMonkey, simply follow the installation instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/gitlab-onprem/gitlab-ce-ee-configuring-webhooks',
            text: 'VCS Provider Webhooks  - GitLab CE/EE'
        }
    ],
}

export const AZURE_DEVOPS_WEBHOOK_MODAL = {
    title: 'VCS Provider Webhooks',
    description: 'To set up Webhooks from your Azure DevOps provider to ControlMonkey, simply follow the installation instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/azure-devops#configuring-webhooks',
            text: 'VCS Provider Webhooks  - Azure DevOps'
        }
    ],
}

export const AZURE_DEVOPS_SERVER_WEBHOOK_MODAL = {
    title: 'VCS Provider Webhooks',
    description: 'To set up Webhooks from your Azure DevOps Server provider to ControlMonkey, simply follow the installation instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/azure-devops-server#configuring-webhooks',
            text: 'VCS Provider Webhooks  - Azure DevOps Server'
        }
    ],
}

export const GITHUB_ENTERPRISE_WEBHOOK_MODAL = {
    title: 'VCS Provider Webhooks',
    description: 'To set up Webhooks from your GitHub Enterprise Server provider to ControlMonkey, simply follow the installation instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/github-enterprise#configuring-webhooks',
            text: 'VCS Provider Webhooks  - GitHub Enterprise Server'
        }
    ],
}

export const BITBUCKET_CLOUD_WEBHOOK_MODAL = {
    title: 'VCS Provider Webhooks',
    description: 'To set up Webhooks from your Bitbucket Cloud provider to ControlMonkey, simply follow the installation instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/bitbucket-cloud#configuring-webhooks',
            text: 'VCS Provider Webhooks  - Bitbucket Cloud'
        }
    ],
}

export const BITBUCKET_DATA_CENTER_WEBHOOK_MODAL = {
    title: 'VCS Provider Webhooks',
    description: 'To set up Webhooks from your Bitbucket Data Center provider to ControlMonkey, simply follow the installation instructions.\nFor detailed steps, refer to our documentation link below.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system/bitbucket-data-center#configuring-webhooks',
            text: 'VCS Provider Webhooks  - Bitbucket Data Center'
        }
    ],
}

export const VIEW_PROVIDER_REPOSITORIES_MODAL_TITLE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_USER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_TEAM_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_PROGRAMMTIC_USER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_PROGRAMMTIC_USER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MANAGE_USER_TOKENS_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_PROGRAMMATIC_USER_TOKEN_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_CUSTOM_ROLE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_CUSTOM_ROLE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CUSTOM_ROLE_MODAL_RESTRICTIONS = {
    btnText: '',
    title: 'Stack Operation Restriction',
    description: <><span className="font-weight-semibold">Restrict read operations to own stacks - </span>if configured, this role will allow stack read/write operations to stacks that were created by the user only<br /><br /><span className="font-weight-semibold">Restrict write operations to own stacks - </span>if configured, this role will allow stack write operations to stacks that were created by the user only. Read operation will not be affected</>,
    isIcon: true,
    learnMoreLinks: [],
}

export const EDIT_REMOTE_BACKEND_PROPERTIES_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EXTRACT_NEW_VARIABLE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_NEW_VARIABLE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_PLAN_FROM_BRANCH_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_PLAN_ADVANCED_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_DEPLOYMENT_FROM_BRANCH_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_DEPLOYMENT_ADVANCED_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_RUN_ADVANCED_BRANCH_NAME = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_RUN_ADVANCED_TARGET_RESOURCES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_RUN_ADVANCED_REPLACE_RESOURCES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_TASK_MODAL = {
    title: 'Task',
    description: 'A Task can be used to run commands directly on your stack',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/runs/task',
            text: 'Task documentation'
        }
    ],
}

export const START_TASK_MODAL_TASK_TYPE = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_TASK_MODAL_RELEASE_STATE_LOCK_LOCK_ID = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_TASK_MODAL_TAINT_RESOURCES_ADDRESSES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_TASK_MODAL_UNTAINT_RESOURCES_ADDRESSES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_TASK_MODAL_REMOVE_RESOURCES_ADDRESSES = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const START_TASK_MODAL_HEAD_BRANCH = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_NOTIFICATION_ENDPOINT_MODAL = {
    btnText: '',
    title: 'Notification Endpoint',
    description: 'Learn more in our documentation how to create notification endpoint.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/notifications',
            text: 'Creating Notifications in ControlMonkey'
        }
    ],
}

export const EDIT_NOTIFICATION_ENDPOINT_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IMPORT_TO_TF_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EVENT_RULE_MODAL = {
    btnText: '',
    title: 'Console Allowed Rule',
    description: 'You can set multiple filters to match a console operation. For example, to ignore every CreateRepository/DeleteRepository operation on us-east-1 , you can define the event names and region accordingly. If you would like to ignore this operation in a specific account, you can use the account filter.\nAt least one filter must be set.\nConsole Allowed operations will still be visible in the UI with an indication on them. They will be ignored when sending notifications.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CUSTOM_MODEL_ERRORS_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const REMEDIATE_RECONCILE_BULK_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const REMEDIATE_RECONCILE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const REMEDIATE_ALIGN_CODE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const VIEW_MISCONFIGURED_STACK_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_AWS_CLOUD_CREDENTIALS_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_GCP_CLOUD_CREDENTIALS_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_AZURE_CLOUD_CREDENTIALS_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const GET_SESSION_INFO_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_IDENTITY_PROVIDER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_IDENTITY_PROVIDER_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CREATE_CUSTOM_ABAC_CONFIGURATION_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const EDIT_CUSTOM_ABAC_CONFIGURATION_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

// this is a copy of STACK_TAB_DEPENDENCIES
export const ADD_STACK_DEPENDENCY_MODAL = {
    title: 'Stack Dependency',
    description: 'Stacks can be dependent on other stacks. If Stack X depends on Stack Y, each time Stack Y is deployed, Stack X will also deploy. Additionally, if both stacks are triggered simultaneously, Stack X will wait for Stack Y to finish its deployment before starting.\n\nFor example, you might want to deploy a networking stack before deploying an application stack that relies on the network configuration.\n\nHere, you can configure the stacks that this stack will depend on.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/stack/stack-dependencies",
            text: "Stack Dependencies"
        }
    ],
}

// this is a copy of STACK_TAB_DEPENDENCIES
export const EDIT_STACK_DEPENDENCY_MODAL = {
    title: 'Stack Dependency',
    description: 'Stacks can be dependent on other stacks. If Stack X depends on Stack Y, each time Stack Y is deployed, Stack X will also deploy. Additionally, if both stacks are triggered simultaneously, Stack X will wait for Stack Y to finish its deployment before starting.\n\nFor example, you might want to deploy a networking stack before deploying an application stack that relies on the network configuration.\n\nHere, you can configure the stacks that this stack will depend on.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/stack/stack-dependencies",
            text: "Stack Dependencies"
        }
    ],
}

export const STACK_DEPENDENCY_REFERENCES_INFO_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const STACK_DEPENDENCY_GRAPH = {
    title: 'Dependency Graph',
    description: 'This graph shows you how this stack is connected to others. The graph shows only the stacks that are directly or indirectly connected to it. This helps you see the immediate network around the stack and understand its dependencies.',
    isIcon: true,
    learnMoreLinks: [],
}
//PAGES
//PAGE - IaC stacks
export const IAC_STACKS_PAGE_HEADER = {
    title: 'Namespaces',
    description: 'Namespaces provide a mechanism for isolating groups of infrastructure.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/namespaces',
            text: 'Namespaces Docs'
        }
    ],
}

export const NAMESPACES_TABLE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const NAMESPACES_POTENTIAL_STACKS_TABLE_HEADER = {
    btnText: '',
    title: 'Potential Stacks',
    description: 'ControlMonkey scans your repos for paths which contain Terraform/Terragrunt code.\nIn this view you will see all paths that are not managed by ControlMonkey.\nYou can create stacks in ControlMonkey by choosing multiple rows, click "Create stacks" and pick a namespace to create these stacks in.',
    isIcon: true,
    learnMoreLinks: [],
}

export const NAMESPACES_STACK_AUTO_DISCOVERY_TABLE_HEADER = {
    btnText: '',
    title: 'Stack Auto Discovery',
    description: 'Automatically create stacks by opening a pull request based on patterns.\nYou can configure path patterns in your repos which will automatically create a stack and start a plan in case a pull request is opened.',
    isIcon: true,
    //learnMoreLinks: [],
}

//PAGE - Templates
export const TEMPLATES_PAGE_HEADER = {
    btnText: '',
    title: 'Self-Service Templates',
    description: 'Self-service templates provide a seamless solution for provisioning cloud infrastructure, enabling DevOps teams to define reusable templates based on Terraform. There are two primary types:\n\n1. Template for Ephemeral Stack: \nDesigned for temporary infrastructure needs. This template type spins up infrastructure using Terraform code, but the resources are not managed by the code after provisioning. It\'s ideal for short-lived deployments or testing scenarios where the infrastructure is temporary.\n\n2. Template for Persistent Stack:\nDesigned for long-term infrastructure management. The Terraform code is copied to a new location in the Git repository under a dedicated branch, and a pull request (PR) is opened. This approach is particularly useful for production workloads that require ongoing management and maintenance through Terraform, ensuring that critical infrastructure resources are consistently managed and version-controlled.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/self-service-templates",
            text: "Self-Service Templates"
        }
    ],
}

export const TEMPLATES_PAGE_TABS_EVENTS_TAB = {
    title: 'Ephemeral Template events',
    description: 'The “Events” tab displays the lifecycle events of stacks from ephemeral templates over the past 7 days.',
    isIcon: true,
    learnMoreLinks: [],
}

//PAGE - Control Policies
export const CONTROL_POLICIES_PAGE_HEADER = {
    title: 'Control Policies',
    description: 'Control policies are used to enforce your organization rules  as part of the Terraform CI/CD.\nThey enforce proactive controls on infrastructure through Terraform code before deployment, flagging/blocking non-compliant changes to prevent risky deployments.\nControlMonkey provides out-of-the-box policies (ControlMonkey Managed) and you can also define your own custom policy (Customer Managed).',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/control-policies',
            text: 'Control Policies'
        }
    ],
}

export const CONTROL_POLICIES_TABS_GROUP_TAB = {
    title: 'Control Policy Group',
    description: 'A reusable collection of control policies for enforcing specific configurations across your cloud infrastructure. This group can include both "Customer Managed" policies, which you define and maintain, as well as "ControlMonkey Managed" policies, which are automatically managed by our system.',
    isIcon: true,
    learnMoreLinks: [],
}

//PAGE - Drift Center
export const DRIFT_CENTER_PAGE_HEADER = {
    btnText: '',
    title: 'Drift Center',
    description: 'The drift center helps identify and address discrepancies between the specified configuration in your Terraform code and the actual state of resources in your cloud environment.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/drift-center',
            text: 'Drift Center'
        }
    ],
}

//PAGE - Resource Finder/Explorer
export const RESOURCE_FINDER_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const RESOURCE_FINDER_SEARCH_WITH_TAGS_SERVICE_ACTIVATION_ERROR = {
    title: 'Resource Explorer - Search with tags',
    description: 'In order to be able to search resource by tags, please enable the ‘AWS Resource Explorer’ service.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.aws.amazon.com/resource-explorer/latest/userguide/getting-started-setting-up.html#getting-started-setting-up-quick',
            text: 'AWS Documentation'
        }
    ],
}

export const RESOURCE_FINDER_SEARCH_WITH_TAGS_CM_POLICY_ERROR = {
    title: 'Resource Explorer - Search with tags',
    description: <>In order to be able to search resource by tags, please add the following permissions to your ControlMonkey Discovery role: 
        <pre style={{fontSize: '80%', backgroundColor: 'lightgray', borderRadius: '5px', padding: '3px'}}>
        {`{
    "Sid": "ResourceExplorer",
    "Effect": "Allow",
    "Action": [
    "resource-explorer-2:Get*",
    "resource-explorer-2:List*",
    "resource-explorer-2:Search",
    "resource-explorer-2:BatchGetView"
    ],
    "Resource": "*"
}`}
        </pre></>,
    isIcon: true,
    learnMoreLinks: [],
}

// Resource Finder/Explorer Related Resources modal
export const RESOURCE_FINDER_RELATED_RESOURCES_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const RESOURCE_FINDER_RELATED_RESOURCES_TYPE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const RESOURCE_FINDER_RELATED_RESOURCES_SEARCH_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}
// Custom Model Related Resources modal (on top of add resources modal)
export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL_RELATED_RESOURCES_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL_RELATED_RESOURCES_TYPE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const MODEL_WIZARD_RESOURCES_TAB_ADD_RESOURCES_MODAL_RELATED_RESOURCES_SEARCH_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Terraform Import
export const IAC_IMPORT_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IAM_IMPORT_VIEW_MODEL_RESOURCES_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IAM_IMPORT_VIEW_MODEL_RESOURCES_TYPE_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const IAM_IMPORT_VIEW_MODEL_RESOURCES_SEARCH_MODAL = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - TF Modules
export const TF_MODULES_PAGE_HEADER = {
    title: 'Terraform Modules Explorer',
    description: 'The Terraform Modules Explorer provides a detailed overview of the Terraform modules used in your code, including their versions and locations.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/terraform-modules-explorer',
            text: 'Terraform Modules Explorer'
        }
    ],
}

//PAGE - TF Providers
export const TF_PROVIDERS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Audit
export const AUDIT_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Users
export const USERS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const PROGRAMMATIC_USERS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CUSTOM_ROLES_PAGE_HEADER = {
    title: 'Custom Roles',
    description: 'Build and configure your own custom namespace roles.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/users-and-roles/custom-roles',
            text: 'Custom Roles'
        },
        {
            url: 'https://docs.controlmonkey.io/administration/users-and-roles',
            text: 'Users and Roles'
        }
    ],
}

export const GENERAL_ORG_CONFIGURATION_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Teams
export const TEAMS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Cloud Credentials
export const CLOUD_CREDENTIALS_PAGE_HEADER = {
    btnText: '',
    title: 'Cloud Credentials',
    description: 'To connect ControlMonley to your cloud provider refer to our documentation',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/on-boarding/how-to-connect-your-aws-account',
            text: 'How to Connect your AWS account'
        }
    ],
}

export const CLOUD_CREDENTIALS_STACK_SET_NOT_CONFIGURED_TABLE = {
    btnText: '',
    title: 'Connect More AWS Accounts',
    description: 'To connect more AWS accounts, add them to the StackSet (in AWS)\nThen return to this page and click on “Connect Accounts”.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CLOUD_CREDENTIALS_AWS_ORGANIZATION_TAB = {
    btnText: '',
    title: 'Organization',
    description: 'Here you can discover the StackSets you used to connect your organization.\nSee which accounts were connected by this StackSet, and connect new accounts that were added to the CloudFormation StackSet at AWS.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/on-boarding/connecting-cloud-account/how-to-connect-your-aws-organization',
            text: 'How to Connect your AWS Organization'
        }
    ],
}

//PAGE - VCS Providers
export const VCS_PROVIDERS_PAGE_HEADER = {
    btnText: '',
    title: 'VCS Providers',
    description: 'ControlMonkey integrates with many version control systems such as GitHub, GitLab, Azure Devops, Bitbucket Cloud and more.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/main-concepts/version-control-system',
            text: 'Main concepts on integrating your version control system.'
        },
        {
            url: 'https://docs.controlmonkey.io/on-boarding/how-to-connect-your-version-control-system',
            text: 'How to connect your version control system.'
        }
    ],
}

//PAGE - Identity Providers
export const IDENTITY_PROVIDERS_PAGE_HEADER = {
    btnText: '',
    title: 'Identity Providers',
    description: 'SSO (Single Sign-On) support makes it easy to manage your organization and works with the most popular identity providers.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/single-sign-on',
            text: 'Single Sign-On'
        }
    ],
}

export const IDENTITY_PROVIDERS_PAGE_SSO_SESSION_TAB = {
    title: 'SSO Sessions',
    description: 'The SSO Sessions table displays your recent SSO sessions from the last 7 days. It shows the session details: email, role, organization and teams.\nEach session was generated using the raw attributes from your Identity Provider (IdP).\nUse this to review and debug your SSO configurations and sessions.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/single-sign-on/advanced-parameters/custom-abac',
            text: 'Single Sign-On - Custom ABAC'
        }
    ],
}

export const SSO_SESSION_TAB_SSO_ATTRIBUTES_COLUMN = {
    title: 'SSO Attributes',
    description: 'These are the raw attributes received from your Identity Provider (IdP) during the SSO sign-in process. Use this information to troubleshoot and verify your SSO configurations.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/single-sign-on/advanced-parameters/custom-abac',
            text: 'Single Sign-On - Custom ABAC'
        }
    ],
}

//PAGE - Custom ABAC Configuration
export const CUSTOM_ABAC_PAGE_HEADER = {
    title: 'ABAC Configuration',
    description: 'When using SSO (Single Sign-On), with Custom ABAC (attribute-based access control) you can configured a mapping between any attribute in your IDP to an Org/Role in ControlMonkey.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/single-sign-on/advanced-parameters/custom-abac',
            text: 'Single Sign-On - Custom ABAC'
        }
    ],
}

//PAGE - Variables
export const VARIABLES_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - SSH Keys
export const SSH_KEYS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Private RUnners
export const PRIVATE_RUNNERS_PAGE_HEADER = {
    btnText: '',
    title: 'Private Runners',
    description: 'To set up a self hosted runner refer to our documentation',
    isIcon: true,
    learnMoreLinks: [
        {
            url: 'https://docs.controlmonkey.io/administration/private-runners',
            text: 'Private Runners'
        }
    ],
}

//Page - Notification Endpoints
export const NOTIFICATION_ENDPOINTS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Cloud Events
export const CLOUD_EVENTS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

export const CLOUD_EVENTS_PAGE_CUSTOM_RULES_TAB_HEADER = {
    btnText: '',
    title: 'Console Allowed Operations',
    description: 'You can define multiple set of rules to suppress specific console operations.\nConsole Allowed operations will still be visible in the UI with an indication on them. They will be ignored when sending notifications.',
    isIcon: true,
    learnMoreLinks: [],
}

//PAGE - Active Runs
export const ACTIVE_RUNS_PAGE_HEADER = {
    title: 'Active Runs',
    description: 'In the \'Active Runs\' view, you can access your currently active Plans/Deployments by selecting the \'Active\' tab. For Plans/Deployments that have concluded within the past 24 hours, you can find them under the \'Finished\' tab.',
    isIcon: true,
    learnMoreLinks: [],
}

export const ACTIVE_RUNS_FINISHED_TAB = {
    title: 'Active Runs - Finished Tab',
    description: 'Presents Runs from the last 24 hours.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CLOUD_EVENTS_TABLE_COLUMN_CONSOLE = {
    title: 'Console Operations',
    description: 'Total count of operations that were executed directly from the AWS Console.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CLOUD_EVENTS_TABLE_COLUMN_IAC = {
    title: 'IaC Operations',
    description: 'Total count of operations that were executed using IaC tools.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CLOUD_EVENTS_STATISTIC_INFO_CONSOLE = {
    title: 'Console Operations',
    description: 'Total count of operations that were executed directly from the AWS Console in the selected period in days.',
    isIcon: true,
    learnMoreLinks: [],
}

export const CLOUD_EVENTS_STATISTIC_INFO_IAC = {
    title: 'IaC Operations',
    description: 'Total count of operations that were executed using IaC tools in the selected period in days.',
    isIcon: true,
    learnMoreLinks: [],
}

//PAGE - Reports
export const REPORTS_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//DASHBOARD
export const DASHBOARD_CONSOLE_OPERATIONS = {
    ...CLOUD_EVENTS_STATISTIC_INFO_CONSOLE
}

export const EDIT_PROFILE_MFA_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - Policy Violations
export const POLICY_VIOLATIONS_PAGE_HEADER = {
    title: 'Policy Violations',
    description: 'A centralized place to view all resources that do not meet your configured control policies.\nWe periodically run these policies across all your stacks to ensure alignment to your standards.\nExplore this page to identify and address any compliance issues efficiently.',
    isIcon: true,
    learnMoreLinks: [],
}

//PAGE - TF Explorer
export const TF_EXPLORER_PAGE_HEADER = {
    btnText: '',
    title: '',
    description: '',
    isIcon: false,
    learnMoreLinks: [],
}

//PAGE - TF Registry
export const TF_REGISTRY_PAGE_HEADER = {
    btnText: '',
    title: 'Private Registry',
    description: 'ControlMonkey Private Registry is a private registry for securely sharing, reusing and version controlling Terraform modules within your organization.\nLeverage modules directly from VCS without sharing credentials, and seamlessly integrate them into ControlMonkey CI/CD.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/terraform-registry",
            text: "Private Registry"
        }
    ],
}

export const TF_REGISTRY_TABS_MODULE_VERSIONS_TABS_VARIABLES_TAB = {
    title: 'Variables',
    description: 'Input variables used within the Terraform Module.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_REGISTRY_TABS_MODULE_VERSIONS_TABS_OUTPUTS_TAB = {
    title: 'Output',
    description: 'Outputs defined in the Terraform Module.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_REGISTRY_TABS_MODULE_VERSIONS_TABS_RESOURCES_TAB = {
    title: 'Resources',
    description: 'Resources utilized in the Terraform Module.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_REGISTRY_TABS_MODULE_VERSIONS_TABS_PROVIDERS_TAB = {
    title: 'Providers',
    description: 'Providers specified in the Terraform Module.',
    isIcon: true,
    learnMoreLinks: [],
}

export const TF_REGISTRY_TABS_MODULE_VERSIONS_TABS_MODULES_TAB = {
    title: 'Modules',
    description: 'External Terraform Modules from sources like the Private Registry or submodules (local paths) used within the Terraform Module. Submodules are accessible in the Submodule dropdown with the same data tabs.',
    isIcon: true,
    learnMoreLinks: [],
}

//PAGE - Stack
export const STACK_TAB_DEPENDENCIES = {
    title: 'Stack Dependency',
    description: 'Stacks can be dependent on other stacks. If Stack X depends on Stack Y, each time Stack Y is deployed, Stack X will also deploy. Additionally, if both stacks are triggered simultaneously, Stack X will wait for Stack Y to finish its deployment before starting.\n\nFor example, you might want to deploy a networking stack before deploying an application stack that relies on the network configuration.\n\nHere, you can configure the stacks that this stack will depend on.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/stack/stack-dependencies",
            text: "Stack Dependencies"
        }
    ],
}

export const STACK_TAB_DEPENDENCIES_FORM_CONFIGURE = {
    title: 'Output References',
    description: 'Stacks can depend on other stacks through outputs. You can configure an output "a" from stack X to be injected as variable "b" into stack Y.\n\nExample:\nNetwork stack (stack X) creates a subnet and outputs its ID.\nWeb Server stack (stack Y) uses the subnet ID from the Network stack to launch an instance.\nIf the subnet ID in the Network stack changes, the Web Server stack will automatically use the new subnet ID.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/stack/stack-dependencies",
            text: "Stack Dependencies"
        }
    ],
}

export const STACK_TAB_DEPENDENCIES_FORM_INCLUDE_SENSITIVE_OUTPUT = {
    title: 'Include Sensitive Outputs',
    description: 'If an output is marked as sensitive, you must check the "Include Sensitive Output" in order for it to be available as an input.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/stack/stack-dependencies",
            text: "Stack Dependencies"
        }
    ],
}

export const STACK_TAB_DEPENDENCIES_FORM_TRIGGER_OPTIONS = {
    title: 'Trigger options',
    description: 'When stack Y depends on stack X via an output, you might want stack Y to deploy after stack X only if the output has changed. You can configure this here. By clicking "Always," stack Y will deploy each time stack X is deployed, regardless of changes. By clicking "On Reference Value Change," it will deploy only if one of the output references has changed.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/stack/stack-dependencies",
            text: "Stack Dependencies"
        }
    ],
}

export const STACK_TAB_CONTROL_POLICIES = {
    title: 'Control Policies',
    description: 'The control policies that are mapped to this stack.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/control-policies",
            text: "Control Policies"
        }
    ],
}

export const STACK_TAB_CONTROL_POLICY_GROUPS = {
    title: 'Control Policy Groups',
    description: 'The control policy groups that are mapped to this stack.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/control-policies/control-policy-groups",
            text: "Control Policy Groups"
        }
    ],
}

export const NAMESPACE_TAB_CONTROL_POLICIES = {
    title: 'Control Policies',
    description: 'The control policies that are mapped to this namespace.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/control-policies",
            text: "Control Policies"
        }
    ],
}

export const NAMESPACE_TAB_CONTROL_POLICY_GROUPS = {
    title: 'Control Policy Groups',
    description: 'The control policy groups that are mapped to this namespace.',
    isIcon: true,
    learnMoreLinks: [
        {
            url: "https://docs.controlmonkey.io/main-concepts/control-policies/control-policy-groups",
            text: "Control Policy Groups"
        }
    ],
}
