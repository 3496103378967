import { APP_PREFIX_PATH } from "configs/AppConfig";
import { ROUTES } from "configs/AppRoutesConfig";

// AWS
export const AWS_REGIONS = [
	{
		id: 'aws-global',
		name: 'AWS Global',
		label: 'Global',
		partition: 'GLOBAL',
		flag: 'None',
		coordinates: {
			x: -1500,
			y: 9400
		}
	},
	{
		id: 'us-gov-west-1',
		name: 'AWS GovCloud (US-West)',
		label: 'US-West',
		partition: 'AWS-US-GOV',
		flag: 'US',
		coordinates: {
			// TODO - set coordinates
		}
	},
	{
		id: 'us-gov-east-1',
		name: 'AWS GovCloud (US-East)',
		label: 'US-East',
		partition: 'AWS-US-GOV',
		flag: 'US',
		coordinates: {
			// TODO - set coordinates
		}
	},
	{
		id: 'cn-northwest-1',
		name: 'China (Ningxia)',
		label: 'Ningxia',
		partition: 'AWS-CN',
		flag: 'CN',
		coordinates: {
			// TODO - set coordinates
		}
	},
	{
		id: 'cn-north-1',
		name: 'China (Beijing)',
		label: 'Beijing',
		partition: 'AWS-CN',
		flag: 'CN',
		coordinates: {
			// TODO - set coordinates
		}
	},
	{
		id: 'ap-east-1',
		name: 'Asia Pacific (Hong Kong)',
		label: 'Hong Kong',
		partition: 'AWS',
		flag: 'HK',
		coordinates: {
			x: 7650,
			y: 7180
		}
	},
	{
		id: 'ap-northeast-2',
		name: 'Asia Pacific (Seoul)',
		label: 'Seoul',
		partition: 'AWS',
		flag: 'KR',
		coordinates: {
			x: 8000,
			y: 7650
		}
	},
	{
		id: 'ap-northeast-3',
		name: 'Asia Pacific (Osaka)',
		label: 'Osaka',
		partition: 'AWS',
		flag: 'JP',
		coordinates: {
			x: 8250,
			y: 7620
		}
	},
	{
		id: 'ap-northeast-1',
		name: 'Asia Pacific (Tokyo)',
		label: 'Tokyo',
		partition: 'AWS',
		flag: 'JP',
		coordinates: {
			x: 8380,
			y: 7620
		}
	},
	{
		id: 'ap-southeast-4',
		name: 'Asia Pacific (Melbourne)',
		label: 'Melbourne',
		partition: 'AWS',
		flag: 'AU',
		coordinates: {
			x: 8520,
			y: 5320
		}
	},
	{
		id: 'ap-southeast-3',
		name: 'Asia Pacific (Jakarta)',
		label: 'Jakarta',
		partition: 'AWS',
		flag: 'ID',
		coordinates: {
			x: 7430,
			y: 6320
		}
	},
	{
		id: 'ap-southeast-2',
		name: 'Asia Pacific (Sydney)',
		label: 'Sydney',
		partition: 'AWS',
		flag: 'AU',
		coordinates: {
			x: 8700,
			y: 5500
		}
	},
	{
		id: 'ap-southeast-1',
		name: 'Asia Pacific (Singapore)',
		label: 'Singapore',
		partition: 'AWS',
		flag: 'SG',
		coordinates: {
			x: 7350,
			y: 6600
		}
	},
	{
		id: 'me-south-1',
		name: 'Middle East (Bahrain)',
		label: 'Bahrain',
		partition: 'AWS',
		flag: 'BH',
		coordinates: {
			x: 5800,
			y: 7300
		}
	},
	{
		id: 'me-central-1',
		name: 'Middle East (UAE)',
		label: 'UAE',
		partition: 'AWS',
		flag: 'UAE',
		coordinates: {
			x: 5900,
			y: 7240
		}
	},
	{
		id: 'ap-south-1',
		name: 'Asia Pacific (Mumbai)',
		label: 'Mumbai',
		partition: 'AWS',
		flag: 'IN',
		coordinates: {
			x: 6450,
			y: 7050
		}
	},
	{
		id: 'ap-south-2',
		name: 'Asia Pacific (Hyderabad)',
		label: 'Hyderabad',
		partition: 'AWS',
		flag: 'IN',
		coordinates: {
			x: 6550,
			y: 7000
		}
	},
	{
		id: 'eu-north-1',
		name: 'EU North (Stockholm)',
		label: 'Stockholm',
		partition: 'AWS',
		flag: 'SE',
		coordinates: {
			x: 4800,
			y: 8500
		}
	},
	{
		id: 'eu-west-3',
		name: 'EU West (Paris)',
		label: 'Paris',
		partition: 'AWS',
		flag: 'FR',
		coordinates: {
			x: 4250,
			y: 8100
		}
	},
	{
		id: 'eu-west-2',
		name: 'EU West (London)',
		label: 'London',
		partition: 'AWS',
		flag: 'UK',
		coordinates: {
			x: 4150,
			y: 8150
		}
	},
	{
		id: 'eu-west-1',
		name: 'EU West (Ireland)',
		label: 'Ireland',
		partition: 'AWS',
		flag: 'IE',
		coordinates: {
			x: 4050,
			y: 8200
		}
	},
	{
		id: 'eu-central-1',
		name: 'EU Central (Frankfurt)',
		label: 'Frankfurt',
		partition: 'AWS',
		flag: 'DE',
		coordinates: {
			x: 4500,
			y: 8150
		}
	},
	{
		id: 'eu-central-2',
		name: 'EU Central (Zurich)',
		label: 'Zurich',
		partition: 'AWS',
		flag: 'CH',
		coordinates: {
			x: 4500,
			y: 8000
		}
	},
	{
		id: 'eu-south-1',
		name: 'Europe (Milan)',
		label: 'Milan',
		partition: 'AWS',
		flag: 'IT',
		coordinates: {
			x: 4500,
			y: 8000
		}
	},
	{
		id: 'eu-south-2',
		name: 'Europe (Spain)',
		label: 'Spain',
		partition: 'AWS',
		flag: 'ES',
		coordinates: {
			x: 4120,
			y: 7750
		}
	},
	{
		id: 'sa-east-1',
		name: 'South America (Sao Paulo)',
		label: 'Sao Paulo',
		partition: 'AWS',
		flag: 'BR',
		coordinates: {
			x: 2900,
			y: 5800
		}
	},
	{
		id: 'ca-central-1',
		name: 'Canada (Central)',
		label: 'Canada Central',
		partition: 'AWS',
		flag: 'CA',
		coordinates: {
			x: 2100,
			y: 8100
		}
	},
	{
		id: 'us-west-2',
		name: 'US West (Oregon)',
		label: 'Oregon',
		partition: 'AWS',
		flag: 'US',
		coordinates: {
			x: 700,
			y: 8000
		}
	},
	{
		id: 'us-west-1',
		name: 'US West (N. California)',
		label: 'N. California',
		partition: 'AWS',
		flag: 'US',
		coordinates: {
			x: 800,
			y: 7800
		}
	},
	{
		id: 'us-east-2',
		name: 'US East (Ohio)',
		label: 'Ohio',
		partition: 'AWS',
		flag: 'US',
		coordinates: {
			x: 1900,
			y: 8000
		}
	},
	{
		id: 'us-east-1',
		name: 'US East (N. Virginia)',
		label: 'N. Virginia',
		partition: 'AWS',
		flag: 'US',
		coordinates: {
			x: 2000,
			y: 7800
		}
	},
	{
		id: 'af-south-1',
		name: 'Africa (Cape Town)',
		label: 'Cape Town',
		partition: 'AWS',
		flag: 'ZA',
		coordinates: {
			x: 4850,
			y: 5500
		}
	},
	{
		id: 'il-central-1',
		name: 'Israel (Tel Aviv)',
		label: 'Tel Aviv',
		partition: 'AWS',
		flag: 'IL',
		coordinates: {
			x: 5290,
			y: 7490
		}
	}
];

export const AWS_SERVICE_TYPE = {
	GLOBAL: 'global',
	REGIONAL: 'regional',
	GLOBAL_AND_REGIONAL: 'global_and_regional'
};

export const AWS_GLOBAL_SERVICES = [
	"CloudFront",
	"IAM",
	"NetworkManager",
	"Route53",
	"Route53RecoveryReadiness",
	"S3",
];

export const AWS_GLOBAL_AND_REGIONAL_SERVICES = [
	"CloudWatch",
	"ECR"
];

export const AWS_GLOBAL_RESOURCE_TYPES = [
	"AWS::CloudFront::",
	"AWS::CloudWatch::Dashboard",
	"AWS::ECR::PublicRepository",
	"AWS::DirectConnect::Gateway",
	"AWS::DirectConnect::GatewayAssociationProposal",
	"AWS::IAM::",
	"AWS::NetworkManager::",
	"AWS::Route53::",
	"AWS::Route53RecoveryReadiness::",
	"AWS::S3::"
];

// Note: this resource type returns from server but we need it as a const
export const AWS_S3_RESOURCE_TYPE = 'AWS::S3::Bucket';

// Azure
export const AZURE_REGIONS = [
	{
        id: "global",
        name: "Global",
        coordinates: {
			x: -1500,
			y: 9400
		}
    },
    {
        id: "eastus",
        name: "(US) East US",
        coordinates: {
			x: 2000, // higher value is right (east), lower value is left (west)
			y: 7800 // higher value is up (north), lower value is down (south)
		},
    },
    {
        id: "eastus2",
        name: "(US) East US 2",
        coordinates: {
            x: 1900,
            y: 800
        },
    },
    {
        id: "southcentralus",
        name: "(US) South Central US",
        coordinates: {
            x: 1300,
            y: 7900
        }
    },
    {
        id: "westus2",
        name: "(US) West US 2",
        coordinates: {
            x: 800,
            y: 7800
        },
    },
    {
        id: "westus3",
        name: "(US) West US 3",
        coordinates: {
            x: 900,
            y: 7800
        },
    },
    {
        id: "australiaeast",
        name: "(Asia Pacific) Australia East",
        coordinates: {
            x: 8520,
            y: 5320
        },
    },
    {
        id: "southeastasia",
        name: "(Asia Pacific) Southeast Asia",
        coordinates: {
            x: 7350,
            y: 6600
        },
    },
    {
        id: "northeurope",
        name: "(Europe) North Europe",
        coordinates: {
            x: 4800,
            y: 8500
        },
    },
    {
        id: "swedencentral",
        name: "(Europe) Sweden Central",
        coordinates: {
            x: 4700,
            y: 8600
        },
    },
    {
        id: "uksouth",
        name: "(Europe) UK South",
        coordinates: {
            x: 4150,
            y: 8150
        },
    },
    {
        id: "westeurope",
        name: "(Europe) West Europe",
        coordinates: {
			x: 4500,
			y: 8000
		},
    },
    {
        id: "centralus",
        name: "(US) Central US",
        coordinates: {
            x: 1300,
            y: 7900
        },
    },
    {
        id: "southafricanorth",
        name: "(Africa) South Africa North",
        coordinates: {
            x: 4850,
            y: 5500
        },
    },
    {
        id: "centralindia",
        name: "(Asia Pacific) Central India",
        coordinates: {
            x: 6550,
            y: 7050
        },
    },
    {
        id: "eastasia",
        name: "(Asia Pacific) East Asia",
        coordinates: {
            x: 7650,
            y: 7180
        },
    },
    {
        id: "japaneast",
        name: "(Asia Pacific) Japan East",
        coordinates: {
            x: 8350,
            y: 7620
        },
    },
    {
        id: "koreacentral",
        name: "(Asia Pacific) Korea Central",
        coordinates: {
            x: 8000,
            y: 7650
        },
    },
    {
        id: "canadacentral",
        name: "(Canada) Canada Central",
        coordinates: {
            x: 2100,
            y: 8100
        },
    },
    {
        id: "francecentral",
        name: "(Europe) France Central",
        coordinates: {
            x: 4250,
            y: 8000
        },
    },
    {
        id: "germanywestcentral",
        name: "(Europe) Germany West Central",
        coordinates: {
            x: 4400,
            y: 8150
        },
    },
    {
        id: "norwayeast",
        name: "(Europe) Norway East",
        coordinates: {
            x: 4450,
            y: 8500
        },
    },
    {
        id: "polandcentral",
        name: "(Europe) Poland Central",
        coordinates: {
            x: 4800,
            y: 8150
        }
    },
    {
        id: "switzerlandnorth",
        name: "(Europe) Switzerland North",
        coordinates: {
            x: 4500,
            y: 8000
        },
    },
    {
        id: "uaenorth",
        name: "(Middle East) UAE North",
        coordinates: {
            x: 5900,
            y: 7240
        },
    },
    {
        id: "brazilsouth",
        name: "(South America) Brazil South",
        coordinates: {
            x: 2900,
            y: 5800
        },
    },
    {
        id: "centraluseuap",
        name: "(US) Central US EUAP",
        coordinates: {
            x: 1300,
            y: 7900
        },
    },
    {
        id: "qatarcentral",
        name: "(Middle East) Qatar Central",
        coordinates: {
            x: 5750,
            y: 7250
        },
    },
    {
        id: "centralusstage",
        name: "(US) Central US (Stage)",
        coordinates: {
            x: 1250,
            y: 7900
        },
    },
    {
        id: "eastusstage",
        name: "(US) East US (Stage)",
        coordinates: {
            x: 1900,
            y: 8000
        },
    },
    {
        id: "eastus2stage",
        name: "(US) East US 2 (Stage)",
        coordinates: {
            x: 2000,
            y: 7800
        },
    },
    {
        id: "northcentralusstage",
        name: "(US) North Central US (Stage)",
		coordinates: {
            x: 1250,
            y: 8200
        },
    },
    {
        id: "southcentralusstage",
        name: "(US) South Central US (Stage)",
		coordinates: {
            x: 1250,
            y: 7600
        },
    },
    {
        id: "westusstage",
        name: "(US) West US (Stage)",
        coordinates: {
            x: 900,
            y: 7800
        },
    },
    {
        id: "westus2stage",
        name: "(US) West US 2 (Stage)",
        coordinates: {
            x: 900,
            y: 7800
        },
    },
    {
        id: "asia",
        name: "Asia",
        coordinates: {
			x: 7200,
			y: 7600
		},
    },
    {
        id: "asiapacific",
        name: "Asia Pacific",
        coordinates: {
			x: 7200,
			y: 7600
		},
    },
    {
        id: "australia",
        name: "Australia",
        coordinates: {
			x: 8520,
			y: 5320
		},
    },
    {
        id: "brazil",
        name: "Brazil",
        coordinates: {
            x: 2900,
            y: 5800
        },
    },
    {
        id: "canada",
        name: "Canada",
        coordinates: {
			x: 2100,
			y: 8100
		},
    },
    {
        id: "europe",
        name: "Europe",
        coordinates: {
			x: 4500,
			y: 8150
		},
    },
    {
        id: "france",
        name: "France",
        coordinates: {
			x: 4250,
			y: 8100
		},
    },
    {
        id: "germany",
        name: "Germany",
        coordinates: {
			x: 4500,
			y: 8150
		},
    },
    {
        id: "india",
        name: "India",
        coordinates: {
            x: 6450,
            y: 7050
        },
    },
    {
        id: "japan",
        name: "Japan",
        coordinates: {
			x: 8250,
			y: 7620
		},
    },
    {
        id: "korea",
        name: "Korea",
        coordinates: {
			x: 8000,
			y: 7650
		},
    },
    {
        id: "norway",
        name: "Norway",
        coordinates: {
            x: 4550,
            y: 8600
        },
    },
    {
        id: "singapore",
        name: "Singapore",
        coordinates: {
			x: 7320,
			y: 6550
		},
    },
    {
        id: "southafrica",
        name: "South Africa",
        coordinates: {
			x: 4850,
			y: 5500
		},
    },
    {
        id: "switzerland",
        name: "Switzerland",
        coordinates: {
			x: 4500,
			y: 8100
		},
    },
    {
        id: "uae",
        name: "United Arab Emirates",
        coordinates: {
            x: 5900,
            y: 7240
        },
    },
    {
        id: "uk",
        name: "United Kingdom",
        coordinates: {
			x: 4150,
			y: 8300
		},
    },
    {
        id: "unitedstates",
        name: "United States",
        coordinates: {
            x: 1300,
            y: 7900
        },
    },
    {
        id: "unitedstateseuap",
        name: "United States EUAP",
        coordinates: {
            x: 1300,
            y: 7900
        },
    },
    {
        id: "eastasiastage",
        name: "(Asia Pacific) East Asia (Stage)",
        coordinates: {
			x: 7650,
			y: 7180
		},
    },
    {
        id: "southeastasiastage",
        name: "(Asia Pacific) Southeast Asia (Stage)",
        coordinates: {
			x: 7430,
			y: 6320
		},
    },
    {
        id: "brazilus",
        name: "(South America) Brazil US",
        coordinates: {
			x: 2900,
			y: 5800
		},
    },
    {
        id: "eastusstg",
        name: "(US) East US STG",
        coordinates: {
			x: 1900,
			y: 8000
		},
    },
    {
        id: "northcentralus",
        name: "(US) North Central US",
        coordinates: {
            x: 1200,
            y: 7950
        },
    },
    {
        id: "westus",
        name: "(US) West US",
        coordinates: {
			x: 700,
			y: 8000
		},
    },
    {
        id: "jioindiawest",
        name: "(Asia Pacific) Jio India West",
        coordinates: {
			x: 6500,
			y: 7000
		},
    },
    {
        id: "eastus2euap",
        name: "(US) East US 2 EUAP",
        coordinates: {
			x: 1900,
			y: 8000
		},
    },
    {
        id: "southcentralusstg",
        name: "(US) South Central US STG",
        coordinates: {
            x: 1200,
            y: 7950
        },
    },
    {
        id: "westcentralus",
        name: "(US) West Central US",
        coordinates: {
			x: 700,
			y: 8000
		},
    },
    {
        id: "southafricawest",
        name: "(Africa) South Africa West",
        coordinates: {
			x: 4800,
			y: 5500
		},
    },
    {
        id: "australiacentral",
        name: "(Asia Pacific) Australia Central",
        coordinates: {
			x: 8080,
			y: 5620
		},
    },
    {
        id: "australiacentral2",
        name: "(Asia Pacific) Australia Central 2",
        coordinates: {
			x: 8080,
			y: 5660
		},
    },
    {
        id: "australiasoutheast",
        name: "(Asia Pacific) Australia Southeast",
        coordinates: {
			x: 8000,
			y: 5660
		},
    },
    {
        id: "japanwest",
        name: "(Asia Pacific) Japan West",
        coordinates: {
			x: 8250,
			y: 7620
		},
    },
    {
        id: "jioindiacentral",
        name: "(Asia Pacific) Jio India Central",
        coordinates: {
			x: 6500,
			y: 7000
		},
    },
    {
        id: "koreasouth",
        name: "(Asia Pacific) Korea South",
        coordinates: {
			x: 8000,
			y: 7650
		},
    },
    {
        id: "southindia",
        name: "(Asia Pacific) South India",
        coordinates: {
			x: 6550,
			y: 6800
		},
    },
    {
        id: "westindia",
        name: "(Asia Pacific) West India",
        coordinates: {
			x: 6450,
			y: 7000
		},
    },
    {
        id: "canadaeast",
        name: "(Canada) Canada East",
        coordinates: {
			x: 2100,
			y: 8200
		},
    },
    {
        id: "francesouth",
        name: "(Europe) France South",
        coordinates: {
            x: 4350,
            y: 7900
        },
    },
    {
        id: "germanynorth",
        name: "(Europe) Germany North",
        coordinates: {
            x: 4500,
            y: 8150
        },
    },
    {
        id: "norwaywest",
        name: "(Europe) Norway West",
        coordinates: {
            x: 4450,
            y: 8500
        },
    },
    {
        id: "switzerlandwest",
        name: "(Europe) Switzerland West",
        coordinates: {
			x: 4500,
			y: 8000
		},
    },
    {
        id: "ukwest",
        name: "(Europe) UK West",
        coordinates: {
			x: 4100,
			y: 8200
		},
    },
    {
        id: "uaecentral",
        name: "(Middle East) UAE Central",
        coordinates: {
            x: 5900,
            y: 7240
        },
    },
    {
        id: "brazilsoutheast",
        name: "(South America) Brazil Southeast",
        coordinates: {
            x: 2900,
            y: 5800
        },
    }
];

// GCP
export const GCP_REGIONS = [
	{
		id: "global",
		name: "Global",
		coordinates: {
			x: -1500,
			y: 9400
		}
	},
	{
		id: "us",
		name: "US (Multi Region)",
		coordinates: {
			x: 1950, // approximate based on US region clusters
			y: 7900
		}
	},
	{
		id: "eu",
		name: "EU (Multi Region)",
		coordinates: {
			x: 4250, // approximate based on EU region clusters
			y: 8100
		}
	},
	{
		id: "africa-south1",
		name: "Johannesburg, South Africa",
		coordinates: {
			x: 4850,
			y: 5500 // based on AWS Cape Town region
		}
	},
	{
		id: "asia-east1",
		name: "Changhua County, Taiwan, APAC",
		coordinates: {
			x: 8300, // close to Japan regions
			y: 7500
		}
	},
	{
		id: "asia-east2",
		name: "Hong Kong, APAC",
		coordinates: {
			x: 7650, // based on AWS Hong Kong region
			y: 7180
		}
	},
	{
		id: "asia-northeast1",
		name: "Tokyo, Japan, APAC",
		coordinates: {
			x: 8380, // based on AWS Tokyo region
			y: 7620
		}
	},
	{
		id: "asia-northeast2",
		name: "Osaka, Japan, APAC",
		coordinates: {
			x: 8250, // based on AWS Osaka region
			y: 7620
		}
	},
	{
		id: "asia-northeast3",
		name: "Seoul, South Korea, APAC",
		coordinates: {
			x: 8000, // based on AWS Seoul region
			y: 7650
		}
	},
	{
		id: "asia-south1",
		name: "Mumbai, India, APAC",
		coordinates: {
			x: 6450, // based on AWS Mumbai region
			y: 7050
		}
	},
	{
		id: "asia-south2",
		name: "Delhi, India, APAC",
		coordinates: {
			x: 6550, // close to Mumbai, AWS Hyderabad region
			y: 7000
		}
	},
	{
		id: "asia-southeast1",
		name: "Jurong West, Singapore, APAC",
		coordinates: {
			x: 7350, // based on AWS Singapore region
			y: 6600
		}
	},
	{
		id: "asia-southeast2",
		name: "Jakarta, Indonesia, APAC",
		coordinates: {
			x: 7430, // based on AWS Jakarta region
			y: 6320
		}
	},
	{
		id: "australia-southeast1",
		name: "Sydney, Australia, APAC",
		coordinates: {
			x: 8700, // based on AWS Sydney region
			y: 5500
		}
	},
	{
		id: "australia-southeast2",
		name: "Melbourne, Australia, APAC",
		coordinates: {
			x: 8520, // based on AWS Melbourne region
			y: 5320
		}
	},
	{
		id: "europe-central2",
		name: "Warsaw, Poland, Europe",
		coordinates: {
			x: 4600, // approximate position close to AWS Frankfurt
			y: 8250
		}
	},
	{
		id: "europe-north1",
		name: "Hamina, Finland, Europe",
		coordinates: {
			x: 4800, // based on AWS Stockholm region
			y: 8500
		}
	},
	{
		id: "europe-southwest1",
		name: "Madrid, Spain, Europe",
		coordinates: {
			x: 4120, // based on AWS Spain region
			y: 7750
		}
	},
	{
		id: "europe-west1",
		name: "St. Ghislain, Belgium, Europe",
		coordinates: {
			x: 4150, // approximate position near London
			y: 8150
		}
	},
	{
		id: "europe-west10",
		name: "Berlin, Germany, Europe",
		coordinates: {
			x: 4500, // approximate based on AWS Frankfurt region
			y: 8100
		}
	},
	{
		id: "europe-west12",
		name: "Turin, Italy, Europe",
		coordinates: {
			x: 4500, // approximate based on AWS Milan region
			y: 8000
		}
	},
	{
		id: "europe-west2",
		name: "London, England, Europe",
		coordinates: {
			x: 4150, // based on AWS London region
			y: 8150
		}
	},
	{
		id: "europe-west3",
		name: "Frankfurt, Germany, Europe",
		coordinates: {
			x: 4500, // based on AWS Frankfurt region
			y: 8150
		}
	},
	{
		id: "europe-west4",
		name: "Eemshaven, Netherlands, Europe",
		coordinates: {
			x: 4050, // close to AWS Ireland
			y: 8200
		}
	},
	{
		id: "europe-west6",
		name: "Zurich, Switzerland, Europe",
		coordinates: {
			x: 4500, // based on AWS Zurich region
			y: 8000
		}
	},
	{
		id: "europe-west8",
		name: "Milan, Italy, Europe",
		coordinates: {
			x: 4500, // based on AWS Milan region
			y: 8000
		}
	},
	{
		id: "europe-west9",
		name: "Paris, France, Europe",
		coordinates: {
			x: 4250, // based on AWS Paris region
			y: 8100
		}
	},
	{
		id: "me-central1",
		name: "Doha, Qatar, Middle East",
		coordinates: {
			x: 5850, // approximate based on Middle East regions
			y: 7200
		}
	},
	{
		id: "me-central2",
		name: "Dammam, Saudi Arabia, Middle East",
		coordinates: {
			x: 5800, // close to AWS Bahrain
			y: 7300
		}
	},
	{
		id: "me-west1",
		name: "Tel Aviv, Israel, Middle East",
		coordinates: {
			x: 5290, // based on AWS Tel Aviv region
			y: 7490
		}
	},
	{
		id: "northamerica-northeast1",
		name: "Montréal, Québec, North America",
		coordinates: {
			x: 2100, // approximate based on AWS Canada region
			y: 8100
		}
	},
	{
		id: "northamerica-northeast2",
		name: "Toronto, Ontario, North America",
		coordinates: {
			x: 2100, // close to AWS Canada region
			y: 8100
		}
	},
	{
		id: "southamerica-east1",
		name: "Osasco, São Paulo, Brazil, South America",
		coordinates: {
			x: 2900, // based on AWS Sao Paulo region
			y: 5800
		}
	},
	{
		id: "southamerica-west1",
		name: "Santiago, Chile, South America",
		coordinates: {
			x: 2750, // approximate based on South America region
			y: 5700
		}
	},
	{
		id: "us-central1",
		name: "Council Bluffs, Iowa, North America",
		coordinates: {
			x: 1750, // approximate based on US regions
			y: 7900
		}
	},
	{
		id: "us-east1",
		name: "Moncks Corner, South Carolina, North America",
		coordinates: {
			x: 2000, // close to AWS N. Virginia
			y: 7800
		}
	},
	{
		id: "us-east4",
		name: "Ashburn, Virginia, North America",
		coordinates: {
			x: 2000, // based on AWS N. Virginia region
			y: 7800
		}
	},
	{
		id: "us-east5",
		name: "Columbus, Ohio, North America",
		coordinates: {
			x: 1900, // based on AWS Ohio region
			y: 8000
		}
	},
	{
		id: "us-south1",
		name: "Dallas, Texas, North America",
		coordinates: {
			x: 1500, // close to US regions
			y: 7900
		}
	},
	{
		id: "us-west1",
		name: "The Dalles, Oregon, North America",
		coordinates: {
			x: 700, // based on AWS Oregon region
			y: 8000
		}
	},
	{
		id: "us-west2",
		name: "Los Angeles, California, North America",
		coordinates: {
			x: 800, // based on AWS N. California region
			y: 7800
		}
	},
	{
		id: "us-west3",
		name: "Salt Lake City, Utah, North America",
		coordinates: {
			x: 1000, // approximate based on US regions
			y: 7900
		}
	},
	{
		id: "us-west4",
		name: "Las Vegas, Nevada, North America",
		coordinates: {
			x: 900, // close to US regions
			y: 7800
		}
	}
];


export const DRIFT_SEVERITY = {
	minor: { "value": 0, "name": "minor" },
	moderate: { "value": 1, "name": "moderate" },
	major: { "value": 2, "name": "major" },
	critical: { "value": 3, "name": "critical" }
}

export const STACK_DRIFT_STATUS = {
	UNKNOWN: 'unknown',
	NOT_CHECKED: 'notChecked',
	IN_SYNC: 'inSync',
	DRIFTED: 'drifted',
	DEPLOY_REQUIRED: 'deployRequired',
}

export const RESOURCE_DRIFT_STATUS = {
	DELETED: 'deleted',
	MODIFIED: 'modified',
	MODIFIED_FORCES_REPLACEMENT: 'modifiedForcesReplacement',
	DEPLOY_REQUIRED: 'deployRequired',
	DEPLOY_REQUIRED_CREATION: 'deployRequiredCreation',
	DEPLOY_REQUIRED_DELETION: 'deployRequiredDeletion',
	DEPLOY_REQUIRED_MODIFICATION: 'deployRequiredModification',
	DEPLOY_REQUIRED_MODIFICATION_FORCE_REPLACEMENT: 'deployRequiredModificationForcesReplacement',
}

export const RESOURCE_DRIFT_STATUS_LABEL = {
	[RESOURCE_DRIFT_STATUS.DELETED]: 'Deleted - The physical resource has been deleted but the logical resource still exists in the terraform code.',
	[RESOURCE_DRIFT_STATUS.MODIFIED]: 'Modified - One or more of the properties of the resource have been changed outside of your terraform code.',
	[RESOURCE_DRIFT_STATUS.MODIFIED_FORCES_REPLACEMENT]: 'Modified Forces Replacement - One or more of the properties of the resource have been changed outside of your terraform code, and running \'Apply\' will replace the physical resource.',
	[RESOURCE_DRIFT_STATUS.DEPLOY_REQUIRED]: 'Deploy Required - The code has been changed but not yet been applied',
	[RESOURCE_DRIFT_STATUS.DEPLOY_REQUIRED_CREATION]: '(+) Deploy Required - The code has been changed but not yet been applied. A new resource was added to the code, running \'Apply\' will create a new resource',
	[RESOURCE_DRIFT_STATUS.DEPLOY_REQUIRED_DELETION]: '(-) Deploy Required - The code has been changed but not yet been applied. An existing resource block is deleted, running \'Apply\' will delete the physical resource.',
	[RESOURCE_DRIFT_STATUS.DEPLOY_REQUIRED_MODIFICATION]: '(~) Deploy Required - The code has been changed but not yet been applied. One or more of the properties of the resource have been changed.',
	[RESOURCE_DRIFT_STATUS.DEPLOY_REQUIRED_MODIFICATION_FORCE_REPLACEMENT]: '(-/+) Deploy Required - The code has been changed but not yet been applied. One or more of the properties of the resource have been changed, running \'Apply\' will replace the physical resource. ',
}

export const DRIFT_REMEDIATION_STATUS = {
	INIT: 'init', // we did not start amything yet.
	IN_PROGRESS: 'inProgress',
	ERROR: 'error',
	FINISHED: 'finished'
}

export const CLOUD_CREDS_TYPE = {
	visibility: { value: "visibility", displayName: "Discovery" },
	executions: { value: "executions", displayName: "Executions" }
}

export const CLOUD_CREDS_PROVIDER_TYPE = {
	AWS: "AWS",
	AZURE: "AZURE",
	GCP: "GCP",
}

export const CLOUD_CREDS_PROVIDER_TYPE_LABELS = {
	[CLOUD_CREDS_PROVIDER_TYPE.AWS]: 'AWS',
	[CLOUD_CREDS_PROVIDER_TYPE.AZURE]: 'Azure',
	[CLOUD_CREDS_PROVIDER_TYPE.GCP]: 'GCP'
};

export const CLOUD_CREDS_PROVIDER_OPTIONS = [
	{
		key: CLOUD_CREDS_PROVIDER_TYPE.AWS,
		label: CLOUD_CREDS_PROVIDER_TYPE_LABELS[CLOUD_CREDS_PROVIDER_TYPE.AWS],
	},
	{
		key: CLOUD_CREDS_PROVIDER_TYPE.AZURE,
		label: CLOUD_CREDS_PROVIDER_TYPE_LABELS[CLOUD_CREDS_PROVIDER_TYPE.AZURE],
	},
	// Add more dashboard providers here
	{
		key: CLOUD_CREDS_PROVIDER_TYPE.GCP,
		// label: 'Google Cloud Platform'
		label: CLOUD_CREDS_PROVIDER_TYPE_LABELS[CLOUD_CREDS_PROVIDER_TYPE.GCP],
	},
];

export const CLOUD_CREDS_PROVIDER_CREDENTIAL_ID = {
	AWS: "awsCredentialsId",
	AZURE: "azureCredentialsId",
	GCP: "gcpCredentialsId"
}

export const DASHBOARD_PROVIDER_ROUTES = {
    [CLOUD_CREDS_PROVIDER_TYPE.AWS]: ROUTES.AWS_DASHBOARD,
    [CLOUD_CREDS_PROVIDER_TYPE.AZURE]: ROUTES.AZURE_DASHBOARD,
    // Add more dashboard providers here
    [CLOUD_CREDS_PROVIDER_TYPE.GCP]: ROUTES.GCP_DASHBOARD
};

export const CLOUD_CREDS_SERVICE_TOKEN_SPLITTER = {
	AWS: "::",
	GCP: "/",
	AZURE: "/"
}

export const SUBSCRIPTION_SCOPE_TYPE = {
	organization: { value: "organization" },
	namespace: { value: "namespace" },
	awsAccount: { value: "awsAccount" }
}

export const VARIABLE_SCOPE_TYPE = {
	organization: { value: "organization" },
	namespace: { value: "namespace" },
	template: { value: "template" },
	blueprint: {value: "blueprint"},
	stack: { value: "stack" }
}

export const VARIABLE_TYPE = {
	TERRAFORM_VARIABLE: 'tfVar',
	ENVIRONMENT_VARIABLE: 'envVar'
}

export const TRIGGER_SCOPE_TYPE = {
	vcs: { value: "vcs" },
	manual: { value: "manual" },
	autoSync: { value: "autoSync" },
	ttl: {value: "ttl"},
	cli: {value: "cli"},
	dependency: {value: "dependency"}
}

export const SUGGESTED_STACK_TYPE = {
	custom: { value: "custom", displayName: "Custom" },
	preBuilt: { value: "preBuild", displayName: "Pre-Built" }
}

export const SUGGESTED_STACK_STATUS = {
	suggestedStackReady: { value: "suggestedStackReady", displayName: "Ready To Import" },
	suggestedStackImported: { value: "suggestedStackImported", displayName: "Imported" },
	suggestedStackCodeReady: { value: "suggestedStackCodeReady", displayName: "Code Ready" },
	calculating: { value: "calculating", displayName: "Calculating" }
}

export const PLAN_DEPLOYMENT_STATE = {
	terraformInit: { value: "terraformInit", displayName: "Terraform Init" },
	tfLint: { value: "tfLint", displayName: "TFLint" },
	tfSec: { value: "tfSec", displayName: "TFSec" },
	terraformPlan: { value: "terraformPlan", displayName: "Terraform Plan" },
	controlPoliciesChecks: { value: "controlPoliciesChecks", displayName: "Control Policies" },
	infracost: { value: "infracost", displayName: "Infracost" },
	terraformApply: { value: "terraformApply", displayName: "Terraform Apply" },
}

export const IAC_TYPES = {
	TERRAFORM: 'terraform',
	TERRAGRUNT: 'terragrunt',
	OPENTOFU: 'opentofu'
};

export const IAC_TYPES_LABELS = {
	[IAC_TYPES.TERRAFORM]: 'Terraform',
	[IAC_TYPES.TERRAGRUNT]: 'Terragrunt',
	[IAC_TYPES.OPENTOFU]: 'OpenTofu'
};

export const V2_DATE = 'March 16, 2023 1:00:00 PM';
export const PLAN_DEPLOYMENT_VESRSION = {
  V1: 'v1',
  V2: 'v2'
};

export const RUNS_STEPS = {
	GIT_CLONE: 'gitCloneResult',
	CUSTOM_FLOW_BEFORE_TERRAFORM_INIT: 'customFlowBeforeTerraformInitResults',
	TERRAFORM_INIT: 'terraformInitResult',
	CUSTOM_FLOW_AFTER_TERRAFORM_INIT: 'customFlowAfterTerraformInitResults',
	TF_LINT: 'tfLintResult',
	TF_SEC: 'tfSecResult',
	CUSTOM_FLOW_BEFORE_TERRAFORM_PLAN: 'customFlowBeforeTerraformPlanResults',
	TERRAFORM_PLAN: 'terraformPlanResult',
	CUSTOM_FLOW_AFTER_TERRAFORM_PLAN: 'customFlowAfterTerraformPlanResults',
	INFRA_COST: 'infracostResult',
	CONTROL_POLICIES: 'controlPoliciesResult',
	CUSTOM_FLOW_BEFORE_TERRAFORM_APPLY: 'customFlowBeforeTerraformApplyResults',
	TERRAFORM_APPLY: 'terraformApplyResult',
	CUSTOM_FLOW_AFTER_TERRAFORM_APPLY: 'customFlowAfterTerraformApplyResults',
	TASK_EXECUTION_RESULT: 'taskExecutionResult'
}

// runs steps gets a backend property in terraform naming convention,
// but it's incorrect when we have IaC type which is not terraform
export const RUNS_STEP_BY_IAC_TYPE_LABELS = {
	[IAC_TYPES.TERRAFORM]: {
		// we leave this value to be mapped from MapBackendValueToDisplayValue as original
		[RUNS_STEPS.TERRAFORM_INIT]: 'terraformInitResult',
		[RUNS_STEPS.TERRAFORM_PLAN]: 'terraformPlanResult',
		[RUNS_STEPS.TERRAFORM_APPLY]: 'terraformApplyResult',
	},
	[IAC_TYPES.TERRAGRUNT]: {
		[RUNS_STEPS.TERRAFORM_INIT]: 'Terragrunt Init',
		[RUNS_STEPS.TERRAFORM_PLAN]: 'Terragrunt Plan',
		[RUNS_STEPS.TERRAFORM_APPLY]: 'Terragrunt Apply',
	},
	[IAC_TYPES.OPENTOFU]: {
		[RUNS_STEPS.TERRAFORM_INIT]: 'Tofu Init',
		[RUNS_STEPS.TERRAFORM_PLAN]: 'Tofu Plan',
		[RUNS_STEPS.TERRAFORM_APPLY]: 'Tofu Apply',
	}
}

export const RUNS_ORDER = [
	RUNS_STEPS.GIT_CLONE,
	RUNS_STEPS.CUSTOM_FLOW_BEFORE_TERRAFORM_INIT,
	RUNS_STEPS.TERRAFORM_INIT,
	RUNS_STEPS.TASK_EXECUTION_RESULT,
	RUNS_STEPS.CUSTOM_FLOW_AFTER_TERRAFORM_INIT,
	RUNS_STEPS.TF_LINT,
	RUNS_STEPS.TF_SEC,
	RUNS_STEPS.CUSTOM_FLOW_BEFORE_TERRAFORM_PLAN,
	RUNS_STEPS.TERRAFORM_PLAN,
	RUNS_STEPS.CUSTOM_FLOW_AFTER_TERRAFORM_PLAN,
	RUNS_STEPS.INFRA_COST,
	RUNS_STEPS.CONTROL_POLICIES,
	RUNS_STEPS.CUSTOM_FLOW_BEFORE_TERRAFORM_APPLY,
	RUNS_STEPS.TERRAFORM_APPLY,
	RUNS_STEPS.CUSTOM_FLOW_AFTER_TERRAFORM_APPLY,
]

export const RUNS_STATUS = {
	NOT_STARTED: "notStarted",
	IN_PROGRESS: "inProgress",
	SUCCEEDED: "succeeded",
	ERROR: "error",
	FAILED: "failed",
	SKIPPED: 'skipped',
	WAITING_FOR_FAILED_CONTROL_POLICIES_APPROVAL: "waitingForFailedControlPoliciesApproval",
	WAITING_FOR_ERROR_CONTROL_POLICIES_APPROVAL: "waitingForErrorControlPoliciesApproval",
	WAITING_FOR_APPROVAL: "waitingForApproval",
	APPROVAL_TIMEOUT: "approvalTimeout",
	// Custom status for UI - does not return in server response
	ISSUES_FOUND: "issuesFound",
	SUCCESS_WITH_WARNING: "successWithWarning"
}

export const RUNS_STATUS_COLORS = {
	[RUNS_STATUS.NOT_STARTED]: 'default',
	[RUNS_STATUS.IN_PROGRESS]: 'blue',
	[RUNS_STATUS.SUCCEEDED]: 'cyan',
	[RUNS_STATUS.ERROR]: 'red',
	[RUNS_STATUS.FAILED]: 'volcano',
	[RUNS_STATUS.SKIPPED]: 'cyan',
	[RUNS_STATUS.WAITING_FOR_FAILED_CONTROL_POLICIES_APPROVAL]: 'purple',
	[RUNS_STATUS.WAITING_FOR_ERROR_CONTROL_POLICIES_APPROVAL]: 'purple',
	[RUNS_STATUS.WAITING_FOR_APPROVAL]: 'purple',
	[RUNS_STATUS.APPROVAL_TIMEOUT]: 'orange',
	[RUNS_STATUS.ISSUES_FOUND]: 'orange',
	[RUNS_STATUS.SUCCESS_WITH_WARNING]: 'orange'
}

export const PLAN_DEPLOYMENT_STATUS = {
	QUEUED: "queued",
    IN_PROGRESS: "inProgress",
    WAITING_FOR_FAILED_CONTROL_POLICIES_APPROVAL: "waitingForFailedControlPoliciesApproval",
    WAITING_FOR_APPROVAL: "waitingForApproval",
    APPROVAL_TIMEOUT: "approvalTimeout",
    SUCCEEDED: "succeeded",
    FAILED: "failed", //policy not passed, critical in tfsec etc..
    ERROR: "error", // error while running a step
    APPLY_FAILURE: "applyFailure",
    CANCELED: "canceled",
	CANCELLING: "cancelling"
}

export const CONTROL_POLICIES_STATUS = {
	PASSED: 'passed',
	FAILED: 'failed',
	ERROR: 'error',
}

export const CONTROL_POLICIES_STATUS_MAP = {
	[CONTROL_POLICIES_STATUS.PASSED]: { "value": 0, "name": "Passed" },
	[CONTROL_POLICIES_STATUS.FAILED]: { "value": 2, "name": "Failed" },
	[CONTROL_POLICIES_STATUS.ERROR]: { "value": 1, "name": "Unable to Check" },
}

export const STEP_ROW_COL_SPAN = 24; // Must be even number
export const STEP_MIN_HEIGHT = "550px";
export const RESOURCES_ACTIONS = {
    CREATE: "Create",
    REPLACE: "Replace",
    UPDATE: "Update",
    DELETE: "Delete",
};

export const TFPLAN_VIEW_TYPES = {
    SPLIT: "split",
    TABLE: "table",
    OUTPUT: "output",
	FULL_OUTPUT: "fullOutput"
};

export const TFSEC_VIEW_TYPES = {
    ALL_RESOURCES: "allResources", // table
    AFFECTED_RESOURCES: "affectedResources", // sub data (affected) table
    OUTPUT: "output",
};

export const INFRA_COST_VIEW_TYPES = {
    TABLE: "table",
	CONTROL_POLICIES: 'controlPolicies',
    OUTPUT: "output",
};

export const CONTROL_POLICIES_VIEW_TYPES = {
    CONTROL_POLICIES_TABLE: "controlPoliciesTable",
	CONTROL_POLICIES_GROUPS_TABLE: 'controlPoliciesGroups',
    OUTPUT: "output",
};

export const ANSI_HIGHLIGHT = {
    PREFIX: '\u001b[44m🔎',
    PREFIX_WO_SEARCH: '\u001b[44m',
    SUFFIX: '\u001b[40m'
};

export const TF_SEC_SEVERITY = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    CRITICAL: 'CRITICAL'
}

export const TF_SEC_SEVERITY_FILE_MAP = {
    [TF_SEC_SEVERITY.LOW]: 'minor',
    [TF_SEC_SEVERITY.MEDIUM]: 'moderate',
    [TF_SEC_SEVERITY.HIGH]: 'major',
    [TF_SEC_SEVERITY.CRITICAL]: 'critical',
}

export const TF_SEC_SEVERITY_LABEL = {
    [TF_SEC_SEVERITY.LOW]: 'Low',
    [TF_SEC_SEVERITY.MEDIUM]: 'Medium',
    [TF_SEC_SEVERITY.HIGH]: 'High',
    [TF_SEC_SEVERITY.CRITICAL]: 'Critical',
}

export const CONTROL_POLICY_GROUPS_SEVERITY = {
	LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
    CRITICAL: 'critical'
}

export const CONTROL_POLICY_GROUPS_SEVERITY_LABEL = {
	[CONTROL_POLICY_GROUPS_SEVERITY.LOW]: 'Low',
    [CONTROL_POLICY_GROUPS_SEVERITY.MEDIUM]: 'Medium',
    [CONTROL_POLICY_GROUPS_SEVERITY.HIGH]: 'High',
    [CONTROL_POLICY_GROUPS_SEVERITY.CRITICAL]: 'Critical'
}

export const CONTROL_POLICY_GROUPS_SEVERITY_OPTIONS = [
	{
		key: CONTROL_POLICY_GROUPS_SEVERITY.LOW,
		label: CONTROL_POLICY_GROUPS_SEVERITY_LABEL[CONTROL_POLICY_GROUPS_SEVERITY.LOW]
	},
	{
		key: CONTROL_POLICY_GROUPS_SEVERITY.MEDIUM,
		label: CONTROL_POLICY_GROUPS_SEVERITY_LABEL[CONTROL_POLICY_GROUPS_SEVERITY.MEDIUM]
	},
	{
		key: CONTROL_POLICY_GROUPS_SEVERITY.HIGH,
		label: CONTROL_POLICY_GROUPS_SEVERITY_LABEL[CONTROL_POLICY_GROUPS_SEVERITY.HIGH]
	},
	{
		key: CONTROL_POLICY_GROUPS_SEVERITY.CRITICAL,
		label: CONTROL_POLICY_GROUPS_SEVERITY_LABEL[CONTROL_POLICY_GROUPS_SEVERITY.CRITICAL]
	},
]

export const CONTROL_POLICY_CATEGORIES = {
	AWS: 'aws',
	AZURE: 'azure',
	GCP: 'gcp',
	GENERAL: 'general',
	SECURITY: 'security',
	COST: 'cost'
}

export const CONTROL_POLICY_CATEGORIES_LABEL = {
	[CONTROL_POLICY_CATEGORIES.AWS]: 'AWS',
	[CONTROL_POLICY_CATEGORIES.AZURE]: 'Azure',
	[CONTROL_POLICY_CATEGORIES.GCP]: 'GCP',
	[CONTROL_POLICY_CATEGORIES.GENERAL]: 'General',
	[CONTROL_POLICY_CATEGORIES.SECURITY]: 'Security',
	[CONTROL_POLICY_CATEGORIES.COST]: 'Cost',
}

export const RUN_REFRESH_INTERVAL_MS = 5000;

export const RUN_SUGGESTED_STACK_BG_SYBC_REFRESH_INTERVAL_MS = 5000;

export const ADD_NEW_OPTION_KEY = -9500

export const CONTROL_POLICY_TYPE_UI = {
	CONTROLMONKEY_MANAGED: 'managed',
	CUSTOMER_MANAGED: 'customerManaged'
}

export const CONTROL_POLICY_TYPE_UI_LABEL = {
	[CONTROL_POLICY_TYPE_UI.CONTROLMONKEY_MANAGED]: 'ControlMonkey Managed',
	[CONTROL_POLICY_TYPE_UI.CUSTOMER_MANAGED]: 'Customer Managed'
}

export const CONTROL_POLICY_TYPES = [
	{
		name: 'Deny Resource Deletion',
		key: 'deny_resource_deletion'
	},
	{
		name: 'TF Resource Property Condition',
		key: 'tf_resource_property_condition'
	},
	{
		name: 'AWS Required Tags',
		key: 'aws_required_tags'
	},
	{
		name: 'Deny Stack Cost Increase ($)',
		key: 'deny_stack_cost_increase'
	},
	{
		name: 'AWS Allowed Regions',
		key: 'aws_allowed_regions'
	},
	{
		name: 'AWS Denied Regions',
		key: 'aws_denied_regions'
	},
	{
		name: 'AWS Allowed Resource Types',
		key: 'aws_allowed_resource_types'
	},
	{
		name: 'AWS Denied Resource Types',
		key: 'aws_denied_resource_types'
	},
	{
		name: 'AWS Allowed Resource Types In Regions',
		key: 'aws_allowed_resource_types_in_regions'
	},
	{
		name: 'AWS Denied Resource Types In Regions',
		key: 'aws_denied_resource_types_in_regions'
	},
	{
		name: 'Terraform Allowed Resource Types',
		key: 'tf_allowed_resource_types'
	},
	{
		name: 'Terraform Denied Resource Types',
		key: 'tf_denied_resource_types'
	},
	{
		name: 'AWS Allowed Intel Instances',
		key: 'aws_allowed_intel_instances'
	},
	{
		name: 'Terraform Allowed Module Sources',
		key: 'tf_allowed_module_sources'
	},
	{
		name: 'Terraform Restricted Module Versions',
		key: 'tf_restricted_module_versions'
	},
	{
		name: 'Terraform Denied Modules',
		key: 'tf_denied_modules'
	},
];

export const CONTROL_POLICY_TYPE_CONTROL_MONKEY_MANAGED = {
	name: CONTROL_POLICY_TYPE_UI_LABEL[CONTROL_POLICY_TYPE_UI.CONTROLMONKEY_MANAGED],
	key: CONTROL_POLICY_TYPE_UI.CONTROLMONKEY_MANAGED
}

export const CONTROL_POLICY_GROUPS_TYPE = {
	MANAGED: 'managed',
	REGULAR: 'regular',
}

export const CONTROL_POLICIES_ENFORCEMENT_LEVEL = {
	WARNING: 'warning',
	SOFT_MANDATORY: 'softMandatory',
	HARD_MANDATORY: 'hardMandatory'
}

export const CONTROL_POLICIES_STATUS_ICON_BY_ENFORCEMENT_LEVEL = {
	SUCCESS: '✅',
	[CONTROL_POLICIES_ENFORCEMENT_LEVEL.WARNING] : '🟡',
	[CONTROL_POLICIES_ENFORCEMENT_LEVEL.SOFT_MANDATORY]: '🟠',
	[CONTROL_POLICIES_ENFORCEMENT_LEVEL.HARD_MANDATORY]: '🔴',
}

// TODO: USER_TYPE and EXTERNAL_INITIATOR_SOURCE should be combined after API fix
//		 For Audit and plan/deployment pages
export const USER_TYPE = {
	controlMonkeyUser: 'controlMonkeyUser',
	controlMonkeySsoUser: 'controlMonkeySsoUser',
	githubUser: 'githubUser',
	gitlabUser: 'gitlabUser',
	bitbucketUser: 'bitbucketUser',
	bitbucketDataCenterUser: 'bitbucketDataCenterUser',
	azureDevopsUser: 'azureDevopsUser',
	controlMonkeyProgrammaticUser: 'controlMonkeyProgrammaticUser',
}

export const EXTERNAL_INITIATOR_SOURCE = {
	GITHUB: 'GitHub',
	GITLAB: 'GitLab',
	AZURE_DEVOPS: 'AzureDevops',
	BITBUCKET: 'BitBucket'
};

export const AUDIT_USER_TYPES = {
    UNKNOWN: '-',
    PROGRAMMATIC: 'programmatic',
    CONSOLE: 'console',
    VCS: 'vcs'
};

export const AUDIT_USER_TYPES_LABELS = {
    [AUDIT_USER_TYPES.UNKNOWN]: '-',
    [AUDIT_USER_TYPES.PROGRAMMATIC]: 'Programmatic',
    [AUDIT_USER_TYPES.CONSOLE]: 'Console',
    [AUDIT_USER_TYPES.VCS]: 'VCS',
};

export const STEP_TO = {
    NEXT: 'next',
    PREVIOUS: 'previous'
}

export const VCS_PROVIDER = {
    GITHUB_COM: 'github.com',
	GITHUB_ENTERPRISE: 'github-enterprise',
	GITHUB_ENTERPRISE_APP: 'github-enterprise-app',
    GITLAB_COM: 'gitlab.com',
    GITLAB_ONPREM: 'gitlab-onprem',
    BITBUCKET_CLOUD: 'bitbucket-cloud',
    BITBUCKET_DATA_CENTER: 'bitbucket-data-center',
    AZURE_DEVOPS: 'azure-devops',
    AZURE_DEVOPS_SERVER: 'azure-devops-server',
};

export const DEFAULT_VCS_PROVIDER_REPOS_PLACEHOLDER = 'Repository Name';
export const VCS_PROVIDER_REPOS_PLACEHOLDER = {
    [VCS_PROVIDER.GITHUB_COM]: DEFAULT_VCS_PROVIDER_REPOS_PLACEHOLDER,
    [VCS_PROVIDER.GITHUB_ENTERPRISE]: DEFAULT_VCS_PROVIDER_REPOS_PLACEHOLDER,
    [VCS_PROVIDER.GITLAB_COM]: '<group>/<project> OR <group>/<subGroup>/<project>',
    [VCS_PROVIDER.GITLAB_ONPREM]: '<group>/<project> OR <group>/<subGroup>/<project>',
    [VCS_PROVIDER.BITBUCKET_CLOUD]: '<workspace>/<repository>',
    [VCS_PROVIDER.BITBUCKET_DATA_CENTER]: '<project>/<repository>',
    [VCS_PROVIDER.AZURE_DEVOPS]: '<orgName>/<project>/<repository>',
    [VCS_PROVIDER.AZURE_DEVOPS_SERVER]: '<orgName>/<project>/<repository>',
};

export const VCS_PROVIDER_PR_ID = {
    [VCS_PROVIDER.GITHUB_COM]: 'githubPullRequestNumber',
    [VCS_PROVIDER.GITHUB_ENTERPRISE]: 'githubPullRequestNumber',
    [VCS_PROVIDER.GITLAB_COM]: 'gitlabMergeRequestIid',
    [VCS_PROVIDER.GITLAB_ONPREM]: 'gitlabMergeRequestIid',
    [VCS_PROVIDER.BITBUCKET_CLOUD]: 'bitbucketPullRequestId',
    [VCS_PROVIDER.BITBUCKET_DATA_CENTER]: 'bitbucketDataCenterPullRequestId',
    [VCS_PROVIDER.AZURE_DEVOPS]: 'azureDevopsPullRequestId',
    [VCS_PROVIDER.AZURE_DEVOPS_SERVER]: 'azureDevopsPullRequestId',
};

export const VCS_PROVIDER_PR_ID_PLACEHOLDER = {
    [VCS_PROVIDER.GITHUB_COM]: 'Enter GitHub Pull Request Number',
    [VCS_PROVIDER.GITHUB_ENTERPRISE]: 'Enter GitHub Pull Request Number',
    [VCS_PROVIDER.GITLAB_COM]: 'Enter GitLab Merge Request Id',
    [VCS_PROVIDER.GITLAB_ONPREM]: 'Enter GitLab Merge Request Id',
    [VCS_PROVIDER.BITBUCKET_CLOUD]: 'Enter BitBucket Pull Request Id',
    [VCS_PROVIDER.BITBUCKET_DATA_CENTER]: 'Enter BitBucket Pull Request Id',
    [VCS_PROVIDER.AZURE_DEVOPS]: 'Enter Azure Devops Pull Request Id',
    [VCS_PROVIDER.AZURE_DEVOPS_SERVER]: 'Enter Azure Devops Pull Request Id',
    GENERIC: 'Enter your Pull Request number:'
};

export const VCS_PROVIDER_PR_ID_APPROVE_MERGE_TEXT = {
    [VCS_PROVIDER.GITHUB_COM]: 'I merged GitHub Pull Request number:',
    [VCS_PROVIDER.GITHUB_ENTERPRISE]: 'I merged GitHub Pull Request number:',
    [VCS_PROVIDER.GITLAB_COM]: 'I merged GitLab Merge Request Id:',
    [VCS_PROVIDER.GITLAB_ONPREM]: 'I merged GitLab Merge Request Id:',
    [VCS_PROVIDER.BITBUCKET_CLOUD]: 'I merged BitBucket Pull Request Id:',
    [VCS_PROVIDER.BITBUCKET_DATA_CENTER]: 'I merged BitBucket Pull Request Id:',
    [VCS_PROVIDER.AZURE_DEVOPS]: 'I merged Azure Devops Pull Request Id:',
    [VCS_PROVIDER.AZURE_DEVOPS_SERVER]: 'I merged Azure Devops Pull Request Id:',
    GENERIC: 'I merged the PR:'
};

export const IMPORT_CODE_PR_TYPE = {
	MANUAL_PR: 'MANUAL_PR',
	CONTROLMONKEY_PR: 'CONTROLMONKEY_PR'
};

export const IAC_IMPORT_ITEMS_SELECTION_MAX = 50;

export const STACK_LOCK_PING_INTERVAL_MS = 60000;
export const STACK_IMPORT_STATUS_RELOAD_INTERVAL_MS = 5000;
export const STACK_IMPORT_STATUS_CMPR_INTERVAL_MS = 2000;

export const IMPORT_STATUS = {
    QUEUED: 'queued',
    IN_PROGRESS: 'inProgress',
    WAITING_FOR_APPROVAL: 'waitingForApproval',
    APPROVAL_TIMEOUT: 'approvalTimeout',
    SUCCESS: 'success',
    CANCELED: 'canceled',
	CANCELLING: 'cancelling',
    ERROR: 'error',
	INITIALIZING_ENV: 'initializingEnvironment',
	VALIDATING_ENV: 'validatingEnvironment',
	IMPORTING_SOURCES: 'importingResources',
	PLANNING: 'planning'
};

export const IMPORT_STATUS_LABEL = {
    [IMPORT_STATUS.QUEUED]: 'In queue',
    [IMPORT_STATUS.IN_PROGRESS]: 'Dry run in progress',
    [IMPORT_STATUS.WAITING_FOR_APPROVAL]: 'Waiting for approval',
    [IMPORT_STATUS.APPROVAL_TIMEOUT]: 'Approval timeout',
    [IMPORT_STATUS.SUCCESS]: 'Success',
    [IMPORT_STATUS.CANCELED]: 'Aborted', // changed label in order not to be confused with cancel wizard button
	[IMPORT_STATUS.CANCELLING]: 'Aborting',
    [IMPORT_STATUS.ERROR]: 'Error',
	[IMPORT_STATUS.INITIALIZING_ENV]: 'Initializing Environment',
	[IMPORT_STATUS.VALIDATING_ENV]: 'Validating Environment',
	[IMPORT_STATUS.IMPORTING_SOURCES]: 'Importing Resources',
	[IMPORT_STATUS.PLANNING]: 'Planning',
	AFTER_APPROVE_IN_PROGRESS: 'Import in progress'
};

export const IMPORT_STATUS_DESCRIPTION = {
    [IMPORT_STATUS.SUCCESS]: 'Successfully imported resources. You should now merge the Pull Request',
    [IMPORT_STATUS.CANCELED]: 'User aborted import resources operation',
}

export const RUN_TYPES = {
    DEPLOYMENT: 'deployment',
    PLAN: 'plan',
	DESTORY: 'destroy',
	TASK: 'task'
};

export const RUN_TYPES_LABELS = {
	[RUN_TYPES.DEPLOYMENT]: 'Deployment',
	[RUN_TYPES.PLAN]: 'Plan',
	[RUN_TYPES.DESTORY]: 'Destroy',
	[RUN_TYPES.TASK]: 'Task'
};

export const RUN_TYPES_MODES = {
	
}

export const DEFAULT_DELETE_MESSAGE_VALUE = 'delete me';

export const MODALS = {
	DELETE_STACK: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_STACK_RESOURCES: {
		CONFIRM_VALUE: 'delete resources',
		CONFIRM_VALUE_AND_STACK: 'delete resources and stack'
	},
	DELETE_USER: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_PROGRAMMTIC_USER: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_PROGRAMMTIC_USER_TOKEN: {
		CONFIRM_VALUE: 'revoke me'
	},
	DELETE_VCS_PROVIDER: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_CLOUD_CREDENTIALS: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_NOTIFICATION_ENDPOINT: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_CUSTOM_ROLE: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_CONTROL_POLICY_GROUP: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DISABLE_2FA_AUTH: {
		CONFIRM_VALUE: 'disable 2FA'
	},
	DELETE_CLOUD_CREDENTIALS_STACK_SET: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
	DELETE_TF_MODULE: {
		CONFIRM_VALUE: DEFAULT_DELETE_MESSAGE_VALUE
	},
};

export const STACK_INIT_STATUS = {
	SUCCEEDED: 'succeeded',
	FAILED: 'failed',
	IN_PROGRESS: 'inProgress',
	UNKNOWN: 'unknown'
};

export const IMPORT_CONTROLMONKEY_PR_STATUS = {
	...STACK_INIT_STATUS
}

export const STACK_INIT_UNKNOWN_FAILURE_MESSAGE = 'Stack failed to initialize with unknown error';

export const VCS_PROVIDER_PR_LINK_PLACEHOLDER = {
    [VCS_PROVIDER.GITHUB_COM]: 'Waiting for GitHub #LINK# Approval',
    [VCS_PROVIDER.GITHUB_ENTERPRISE]: 'Waiting for GitHub #LINK# Approval',
    [VCS_PROVIDER.GITLAB_COM]: 'Waiting for GitLab #LINK# Approval',
    [VCS_PROVIDER.GITLAB_ONPREM]: 'Waiting for GitLab #LINK# Approval',
    [VCS_PROVIDER.BITBUCKET_CLOUD]: 'Waiting for BitBucket #LINK# Approval',
    [VCS_PROVIDER.BITBUCKET_DATA_CENTER]: 'Waiting for BitBucket #LINK# Approval',
    [VCS_PROVIDER.AZURE_DEVOPS]: 'Waiting for Azure Devops #LINK# Approval',
    [VCS_PROVIDER.AZURE_DEVOPS_SERVER]: 'Waiting for Azure Devops Server #LINK# Approval',
    GENERIC: 'Waiting for #LINK# Approval'
};

export const STACK_FROM_BLUEPRINT_INIT_STATUS = {
	...STACK_INIT_STATUS,
	MERGED: 'merged',
	WAITING_FOR_PR_APPROVAL: 'waitingForPrApproval',
};

export const CLOUD_EVENTS = {
	ACTION_SOURCE_TYPE: {
		CONSOLE: 'console',
		CONSOLE_ALLOWED: 'consoleAllowed',
		IAC: 'terraform'
	},
	RESOURCES_DELIMITER: '_#_'
};

export const CLOUD_EVENTS_SOURCE_UI_LABEL = {
	[CLOUD_EVENTS.ACTION_SOURCE_TYPE.CONSOLE]: 'Console',
	[CLOUD_EVENTS.ACTION_SOURCE_TYPE.CONSOLE_ALLOWED]: 'Console (Allowed)',
	[CLOUD_EVENTS.ACTION_SOURCE_TYPE.IAC]: 'IaC',
}

export const CLOUD_EVENTS_PERIOD = {
    DEFAULT_DAYS_BACK: 30,
    DAYS_BACK_OPTIONS: [7, 14, 30],
};

const STACK_PATH_PREFIX = '${stack_path}';
export const RUN_TRIGGER = {
	STACK_PATH_PREFIX,
	SUB_DIRECTORIES_PREFIX: `${STACK_PATH_PREFIX}/**/*`
}


const PERIOD = {
    WEEK: 7,
    TWO_WEEKS: 14,
    THREE_WEEKS: 21,
    FOUR_WEEKS: 28,
    THREE_MONTHES: 91, // By 13 weeks where each week is 7 days
    MAX: 182,
};

// aggregation by day includes current day, with week must be exact number of days
const TIME_PERIOD_OPTIONS = [
    {
        label: "Last 7 Days",
        aggregateBy: "day",
        daysBack: PERIOD.WEEK - 1,
    },
    {
        label: "Last 14 Days",
        aggregateBy: "day",
        daysBack: PERIOD.TWO_WEEKS - 1,
    },
    {
        label: "Last 3 Weeks",
        aggregateBy: "week",
        daysBack: PERIOD.THREE_WEEKS,
    },
    {
        label: "Last Month",
        aggregateBy: "week",
        daysBack: PERIOD.FOUR_WEEKS,
    },
    {
        label: "Last 3 Months",
        aggregateBy: "week",
        daysBack: PERIOD.THREE_MONTHES,
    },
    {
        label: "Last 6 Months",
        aggregateBy: "week",
        daysBack: PERIOD.MAX,
    },
];

export const DASHBOARD_COVERAGE = {
	PERIOD,
	TIME_PERIOD_OPTIONS,
	LABELS: {
		ALL_SERVICES: 'All Services',
		ALL_RESOURCE_TYPES: 'All Types'
	}
};

export const EXTERNAL_CREDENTIAL_TYPE = {
	AWS: "awsAssumeRole",
	GCP: "gcpServiceAccount",
	AZURE: "azureServicePrincipal"
};

export const DROPDOWN_OPTION_NONE = 'none';

export const PROMISE_STATUS = {
	FULFILLED: 'fulfilled',
	REJECTED: 'rejected'
}

export const NO_COST_SET = -1;
export const NO_IAC_TYPE_SET = "noIacType";

export const ROLE = {
	ADMIN: {
		key: 'admin',
		label: 'Admin'
	},
	// Deploy role is deprecated
	// DEPLOYER: {
	// 	key: 'deployer',
	// 	label: 'Deployer'
	// },
	VIEWER: {
		key: 'viewer',
		label: 'Viewer'
	},
	MEMBER: {
		key: 'member',
		label: 'Member'
	},
	RUNNER: {
		key: 'runner',
		label: 'Runner'
	},
	NS_ONLY: {
		key: 'nsOnly',
		label: 'Namespace Only'
	}
}

export const ENDPOINT_PROTOCOL = {
	SLACK: {
		key: 'slack',
		label: 'Slack'
	},
	TEAMS: {
		key: 'teams',
		label: 'Teams'
	}
}

export const SYSTEM_USER_TYPES = {
	USER: 'user',
	PROGRAMMATIC_USER: 'programmaticUser'
}

export const NAMESPACE_PERMISSION_TYPES = {
	TEAM: 'team',
	...SYSTEM_USER_TYPES,
}

export const PRICING_PLAN = {
	FREE_TRIAL: 'freeTrial',
	BASIC: 'basic',
	PRO: 'pro',
	ENTERPRISE: 'enterprise'
}

export const ADD_TEAM_QUERY_PARAM = 'addTeam';
export const INVITE_NEW_USER_QUERY_PARAM = 'inviteUser';
export const ADD_PROGRAMMATIC_USER_QUERY_PARAM = 'addPUser';

export const CLOUD_PROVIDER_AWS_QUERY_PARAM = 'cloudProviderAWS';
export const CLOUD_PROVIDER_AWS_QUERY_PARAM_EXECUTIONS = 'cloudProviderAWSExecutions';
export const CLOUD_PROVIDER_AZURE_QUERY_PARAM = 'cloudProviderAzure';
export const CLOUD_PROVIDER_GCP_QUERY_PARAM = 'cloudProviderGCP';
export const CLOUD_PROVIDER_AWS_ORGANIZATION_QUERY_PARAM = 'awsOrganization';
export const CLOUD_PROVIDER_AWS_ORGANIZATION_SS_DISCOVERY_QUERY_PARAM = CLOUD_CREDS_TYPE.visibility.value;
export const CLOUD_PROVIDER_AWS_ORGANIZATION_SS_EXECUTIONS_QUERY_PARAM = CLOUD_CREDS_TYPE.executions.value;

export const AWS_ORG_STACKSET_CFN_STATUS_COLORS = {
	SUCCEEDED: 'green',
	RUNNING: 'blue',
	FAILED: 'red',
	CANCELLED: 'red',
	UNKNOWN: 'gray'
};

export const ADD_CUSTOM_ROLE_QUERY_PARAM = 'addCustomRole';

// source code tab
export const PLACEHOLDER_TYPES = {
    COMPONENT: {
        PREFIX: "%COMPONENT-",
        NAME: "component",
    },
    VALUE: {
        PREFIX: "%VALUE-",
        NAME: "value",
    },
    SECTION: {
        PREFIX: "%SECTION-",
        NAME: "section",
    },
};

export const PLACEHOLDERS_COMPONENT_TYPES = {
    RESOURCE_NAME: "RESOURCES_NAME",
    EXTRACT_VAR: "EXTRACT_VAR",
	EXISTING_VAR: "EXISTING_VAR",
	VARIABLE_NAME: "VARIABLES_NAME"
};

export const PLACEHOLDERS_SECTION_TYPES = {
    ADDED_VARS: "ADDED_VARS",
	EXISTING_VARS: "EXISTING_VARS",
	REMOTE_BACKEND: "REMOTE_BACKEND"
};

export const INVALID_RESOURCE_OR_VAR_NAME_INPUT =
    "A name must start with a letter or underscore and may contain only letters, digits, underscores, and dashes.";

export const TF_VARIABLE_ALREADY_EXISTS = "Variable name already exists in code";

export const RESOURCE_NAME_MAX_LENGTH = 250;

export const VARIABLE_NAME_MAX_LENGTH = 128;

export const TTL = {
	TYPE: {
		DAYS: {
			LABEL: 'Day(s)',
			VALUE: 'days',
		},
		HOURS: {
			LABEL: 'Hour(s)',
			VALUE: 'hours',
		}
	},
}

export const INIT_TTL = {
	INPUT_MIN: 1,
	INPUT_MAX: 100000, // backend has much lower but we must have an init max value
	CONFIGS: {
		// currently not in use since without default config it will show empty min value and no type selected
		MIN: {
			type: TTL.TYPE.HOURS.VALUE,
			value: 1
		},
		MAX: {
			type: TTL.TYPE.HOURS.VALUE,
			value: 100000
		}
	}
};

export const TEMPLATE_TYPES = {
	EPHEMERAL: 'Ephemeral', // regular template
	PERSISTENT: 'Persistent' // blueprint
}

export const TEMPLATE_EVENT_TYPES = {
	STACK_CREATED: 'stackCreated',
	STACK_DELETED: 'stackDeleted',
	RESOURCE_CREATION_COMPLETE: 'resourceCreationComplete',
	RESOURCE_CREATION_FAILED: 'resourceCreationFailed',
	RESOURCE_TERMINATION_STARTED: 'resourceTerminationStarted',
	RESOURCE_TERMINATION_COMPLETE: 'resourceTerminationComplete',
	RESOURCE_TERMINATION_FAILED: 'resourceTerminationFailed',
}

export const TEMPLATE_EVENT_TYPES_LABELS = {
	[TEMPLATE_EVENT_TYPES.STACK_CREATED]: 'Stack Created',
	[TEMPLATE_EVENT_TYPES.STACK_DELETED]: 'Stack Deleted',
	[TEMPLATE_EVENT_TYPES.RESOURCE_CREATION_COMPLETE]: 'Resource Creation Complete',
	[TEMPLATE_EVENT_TYPES.RESOURCE_CREATION_FAILED]: 'Resource Creation Failed',
	[TEMPLATE_EVENT_TYPES.RESOURCE_TERMINATION_STARTED]: 'Resource Termination Started',
	[TEMPLATE_EVENT_TYPES.RESOURCE_TERMINATION_COMPLETE]: 'Resource Termination Succeeded',
	[TEMPLATE_EVENT_TYPES.RESOURCE_TERMINATION_FAILED]: 'Resource Termination Failed',
}

export const STACK_PATH_PATTERN_PLACEHOLDER_TOKEN = "<>";
export const STACK_PATH_PATTERN_PLACEHOLDER_TOKEN_FIND = /<>/g;

// Resource Finder check if tags enabled error(s) to supress in fetcher and handle in UI
export const RESOURCES_TAGS_SERVICE_ACTIVATION_ERROR = "Missing prerequisites for this feature, please contact support in order to use it";
export const RESOURCES_TAGS_CM_POLICY_ERROR = "Missing aws permissions for this feature, please contact support in order to use it";
export const RESOURCES_TAGS_TEST_ENDPOINT_ERROR = "Failed to get aws resources test endpoint"; // this error is in case we have issues with the API such as rate limit etc

export const IAC_STATE_STATUS = {
	UNMANAGED: 'unmanaged',
	MANAGED: 'managed',
	NOT_RELEVANT: 'notRelevant',
	// from here custom values for UI set after fetching from server
	PENDING_CALCULATION: 'pendingCalculation',
	NOT_AVAILABLE: 'notAvailable'
};

export const IAC_STATE_STATUS_LABELS = {
	[IAC_STATE_STATUS.UNMANAGED]: 'Unmanaged',
	[IAC_STATE_STATUS.MANAGED]: 'Managed',
	[IAC_STATE_STATUS.NOT_RELEVANT]: 'Not Relevant',
	[IAC_STATE_STATUS.PENDING_CALCULATION]: 'Pending Calculation',
	[IAC_STATE_STATUS.NOT_AVAILABLE]: 'N/A'
};

export const DEFAULT_RUNNER_GROUP = "CM_DEFAULT_RUNNER_GROUP";
export const DEFAULT_RUNNER_GROUP_VALUE = "DEFAULT";
export const HEARTBEAT_UPDATE_THRESHOLD_IN_HOURS = 12; // for the heartbeat endpoint
// Private runners heartbeat threshold to its status to be good/bad
export const HEARTBEAT_THRESHOLD_IN_MINUTES_ERROR = 8;
export const HEARTBEAT_THRESHOLD_IN_MINUTES_WARNING = 5;

export const RUNNER_STATUS_TYPES = {
	OK: 'ok',
	WARNING: 'warning',
	ERROR: 'error',
	SHUTTING_DOWN: 'shuttingDown',
};

export const RUNNER_STATUS_TYPES_EMOJI = {
	[RUNNER_STATUS_TYPES.OK]: '🟢',
	[RUNNER_STATUS_TYPES.WARNING]: '🟡',
	[RUNNER_STATUS_TYPES.ERROR]: '🔴',
	[RUNNER_STATUS_TYPES.SHUTTING_DOWN]: '🟠',
};

export const RUNNER_STATUS_TYPES_LABELS = {
	[RUNNER_STATUS_TYPES.OK]: 'Healthy',
	[RUNNER_STATUS_TYPES.WARNING]: 'Missing Heartbeat',
	[RUNNER_STATUS_TYPES.ERROR]: 'UnHealthy',
	[RUNNER_STATUS_TYPES.SHUTTING_DOWN]: 'Shutting Down',
};

export const AUDIT_RESOURCE_TYPES = {
	STACK: 'stack',
	STACK_RESOURCES_IMPORT: 'stackResourcesImport',
	STACK_RESOURCES: 'stackResources',
	STACK_INITIALIZATION_RETRY: 'stackInitializationRetry',
	PLAN: 'plan',
	DEPLOYMENT: 'deployment',
	TASK: 'task',
	CUSTOM_MODEL: 'customModel',
	USER: 'user',
	TEAM: 'team',
	NAMESPACE_PERMISSION: 'namespacePermission',
	IDENTITY_PROVIDER: 'identityProvider',
	TEMPLATE: 'template',
	TEMPLATE_NAMESPACE_MAPPING: 'templateNamespaceMapping',
	BLUEPRINT: 'blueprint',
	BLUEPRINT_NAMESPACE_MAPPING: 'blueprintNamespaceMapping',
	CONTROL_POLICY: 'controlPolicy',
	CONTROL_POLICY_NAMESPACE_MAPPING: 'controlPolicyNamespaceMapping',
	VCS_CREDENTIALS: 'vcsCredentials',
	NAMESPACE: 'namespace',
	SSH_KEY: 'sshKey',
	GCP_CREDENTIALS: 'gcpCredentials',
	AZURE_CREDENTIALS: 'azureCredentials',
	DRIFT_DETECTION: 'driftDetection',
	CUSTOM_ABAC_CONFIGURATION: 'customAbacConfiguration'
}

export const AUDIT_RESOURCE_TYPES_LINK = {
	[AUDIT_RESOURCE_TYPES.STACK]: `${APP_PREFIX_PATH}/stack/:id`,
	[AUDIT_RESOURCE_TYPES.STACK_RESOURCES_IMPORT]: `${APP_PREFIX_PATH}/stack/:id`,
	[AUDIT_RESOURCE_TYPES.STACK_RESOURCES]: `${APP_PREFIX_PATH}/stack/:id?tab=resources`,
	[AUDIT_RESOURCE_TYPES.STACK_INITIALIZATION_RETRY]: `${APP_PREFIX_PATH}/stack/:id`,
	[AUDIT_RESOURCE_TYPES.PLAN]: `${APP_PREFIX_PATH}/plan/:id`,
	[AUDIT_RESOURCE_TYPES.DEPLOYMENT]: `${APP_PREFIX_PATH}/deployment/:id`,
	[AUDIT_RESOURCE_TYPES.TASK]: `${APP_PREFIX_PATH}/task/:id`,
	[AUDIT_RESOURCE_TYPES.CUSTOM_MODEL]: `${APP_PREFIX_PATH}/iacImport`,
	[AUDIT_RESOURCE_TYPES.USER]: `${APP_PREFIX_PATH}/organization/setting?id=user-list`,
	[AUDIT_RESOURCE_TYPES.TEAM]: `${APP_PREFIX_PATH}/team/:id`,
	[AUDIT_RESOURCE_TYPES.NAMESPACE_PERMISSION]: `${APP_PREFIX_PATH}/namespace/:id`,
	[AUDIT_RESOURCE_TYPES.IDENTITY_PROVIDER]: `${APP_PREFIX_PATH}/organization/setting?id=idp`,
	[AUDIT_RESOURCE_TYPES.TEMPLATE]: `${APP_PREFIX_PATH}/templates`,
	[AUDIT_RESOURCE_TYPES.TEMPLATE_NAMESPACE_MAPPING]: `${APP_PREFIX_PATH}/templates`,
	[AUDIT_RESOURCE_TYPES.BLUEPRINT]: `${APP_PREFIX_PATH}/templates`,
	[AUDIT_RESOURCE_TYPES.BLUEPRINT_NAMESPACE_MAPPING]: `${APP_PREFIX_PATH}/templates`,
	[AUDIT_RESOURCE_TYPES.CONTROL_POLICY]: `${APP_PREFIX_PATH}/controlPolicies`,
	[AUDIT_RESOURCE_TYPES.CONTROL_POLICY_NAMESPACE_MAPPING]: `${APP_PREFIX_PATH}/controlPolicies`,
	[AUDIT_RESOURCE_TYPES.VCS_CREDENTIALS]: `${APP_PREFIX_PATH}/organization/setting?id=vcs-providers`,
	[AUDIT_RESOURCE_TYPES.NAMESPACE]: `${APP_PREFIX_PATH}/namespace/:id`,
	[AUDIT_RESOURCE_TYPES.SSH_KEY]: `${APP_PREFIX_PATH}/organization/setting?id=ssh-keys`,
	[AUDIT_RESOURCE_TYPES.GCP_CREDENTIALS]: `${APP_PREFIX_PATH}/organization/setting?id=cloud-creds`,
	[AUDIT_RESOURCE_TYPES.AZURE_CREDENTIALS]: `${APP_PREFIX_PATH}/organization/setting?id=cloud-creds`,
	[AUDIT_RESOURCE_TYPES.DRIFT_DETECTION]: `${APP_PREFIX_PATH}/stack/:id`,
	[AUDIT_RESOURCE_TYPES.CUSTOM_ABAC_CONFIGURATION]: `${APP_PREFIX_PATH}/organization/setting?id=abac`,
}

export const CURRENCY = {
	USD: 'USD'
};

export const ORGANIZATION_SETTINGS = {
	TYPE: {
		MANAGEMENT: 'management', // org management
		REGULAR: 'regular' // return today as Default
	},
	GENERAL: {
		REPORT_CONFIGURATIONS: {
			TYPES: {
				WEEKLY_REPORT: 'weeklyReport'
			}
		},
		RUNNER_CONFIG: {
			TYPES: {
				MANAGED: {
					label: 'Managed',
					key: 'managed'
				},
				SELF_HOSTED: {
					label: 'Self Hosted',
					key: 'selfHosted'
				}
			}
		}
	},
	VCS_PROVIDERS: {
		BITBUCKET_CLOUD: {
			AUTH_OPTION: {
				PASSWORD: 'password',
				TOKEN: 'token'
			},
		},
		BITBUCKET_DATA_CENTER: {
			AUTH_OPTION: {
				PASSWORD: 'password',
				TOKEN: 'token'
			},
		},
		GITLAB: {
			AUTH_OPTION: {
				TOKEN: 'token',
				GROUP_PROJECT_TOKEN: 'groupProjectToken'
			},
		},
		AZURE_DEVOPS: {
			AUTH_OPTION: {
				ALL_ACCESS_ORG: 'allAccessOrg',
				SPECIFIC_ORG: 'specificOrg'
			}
		}
	}
};

export const ORGANIZATION_SETTINGS_BITBUCKET_AUTH_OPTIONS_LIST = [
	{
		label: 'Configure with user password',
		value: ORGANIZATION_SETTINGS.VCS_PROVIDERS.BITBUCKET_CLOUD.AUTH_OPTION.PASSWORD
	},
	{
		label: 'Configure with token',
		value: ORGANIZATION_SETTINGS.VCS_PROVIDERS.BITBUCKET_CLOUD.AUTH_OPTION.TOKEN
	}
];

export const ORGANIZATION_SETTINGS_GITLAB_AUTH_OPTIONS_LIST = [
	{
		label: 'Configure with personal access token',
		value: ORGANIZATION_SETTINGS.VCS_PROVIDERS.GITLAB.AUTH_OPTION.TOKEN
	},
	{
		label: 'Configure with group/project access token',
		value: ORGANIZATION_SETTINGS.VCS_PROVIDERS.GITLAB.AUTH_OPTION.GROUP_PROJECT_TOKEN
	}
];

export const ORGANIZATION_SETTINGS_AZURE_DEVOPS_AUTH_OPTIONS_LIST = [
	{
		label: 'I\'ve created a personal access token for \'All accessible organization\'',
		value: ORGANIZATION_SETTINGS.VCS_PROVIDERS.AZURE_DEVOPS.AUTH_OPTION.ALL_ACCESS_ORG
	},
	{
		label: 'I\'ve created a personal access token for a specific organization',
		value: ORGANIZATION_SETTINGS.VCS_PROVIDERS.AZURE_DEVOPS.AUTH_OPTION.SPECIFIC_ORG
	}
];

// custom role consts
export const CUSTOM_ROLE_PERMISSIONS_CATEGORIES = {
    STACK: 'Stack',
    DEPLOYMENT: 'Deployment',
	PLAN: 'Plan',
	DRIFT_DETECTION: 'Drift Detection',
	VARIABLE: 'Variable'
}

const CUSTOM_ROLE_PERMISSION_NAMES = {
	// Stack category
    STACK_CREATE: 'stack:create',
    STACK_UPDATE: 'stack:update',
    STACK_DELETE: 'stack:delete',
    STACK_CREATE_FROM_TEMPLATE: 'stack:createFromTemplate',
    STACK_CREATE_FROM_BLUEPRINT: 'stack:createFromBlueprint',
	STACK_EXTEND_TTL: 'stack:extendTtl',
	STACK_RETRY_INITILIZATION: 'stack:retryInitialization',
	// Deployment category
    DEPLOYMENT_APPROVE: 'deployment:approve',
    DEPLOYMENT_APPROVE_FAILED_POLICIES: 'deployment:approveFailedPolicies',
    DEPLOYMENT_CANCEL: 'deployment:cancel',
    DEPLOYMENT_START: 'deployment:start',
    DEPLOYMENT_DESTORY_DEPLOYMENT_START: 'destroyDeployment:start',
	// Plan category
	PLAN_START: 'plan:start',
	PLAN_CANCEL: 'plan:cancel',
	// Drift detection category
	DRIFT_DETECTION_START: 'driftDetection:start',
	// Variable category
	VARIABLE_CREATE_STACK: 'stackVariable:create',
	VARIABLE_UPDATE_STACK: 'stackVariable:update',
	VARIABLE_DELETE_STACK: 'stackVariable:delete',
}

export const CUSTOM_ROLE_PERMISSIONS = {
    [CUSTOM_ROLE_PERMISSIONS_CATEGORIES.STACK]: [
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.STACK_CREATE],
            displayName: 'Create Stack'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.STACK_UPDATE],
            displayName: 'Update Stack'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.STACK_DELETE],
            displayName: 'Delete Stack'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.STACK_CREATE_FROM_TEMPLATE],
            displayName: 'Create Stack From Ephemeral Template'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.STACK_CREATE_FROM_BLUEPRINT],
            displayName: 'Create Stack From Persistent Template'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.STACK_EXTEND_TTL],
            displayName: 'Extend TTL'
        },
		{
            name: [CUSTOM_ROLE_PERMISSION_NAMES.STACK_RETRY_INITILIZATION],
            displayName: 'Retry Stack Initiazlization'
        }
    ],
    [CUSTOM_ROLE_PERMISSIONS_CATEGORIES.DEPLOYMENT]: [ 
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.DEPLOYMENT_APPROVE],
            displayName: 'Approve Deployment'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.DEPLOYMENT_APPROVE_FAILED_POLICIES],
            displayName: 'Approve Failed Policies'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.DEPLOYMENT_CANCEL],
            displayName: 'Abort Deployment'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.DEPLOYMENT_START],
            displayName: 'Start Deployment'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.DEPLOYMENT_DESTORY_DEPLOYMENT_START],
            displayName: 'Delete Stack Resources'
        },
    ],
    [CUSTOM_ROLE_PERMISSIONS_CATEGORIES.PLAN]: [ 
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.PLAN_START],
            displayName: 'Start Plan'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.PLAN_CANCEL],
            displayName: 'Abort Plan'
        },
    ],
    [CUSTOM_ROLE_PERMISSIONS_CATEGORIES.DRIFT_DETECTION]: [ 
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.DRIFT_DETECTION_START],
            displayName: 'Start Drift Detection'
        },
    ],
	[CUSTOM_ROLE_PERMISSIONS_CATEGORIES.VARIABLE]: [ 
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.VARIABLE_CREATE_STACK],
            displayName: 'Create Stack Variable'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.VARIABLE_UPDATE_STACK],
            displayName: 'Update Stack Variable'
        },
        {
            name: [CUSTOM_ROLE_PERMISSION_NAMES.VARIABLE_DELETE_STACK],
            displayName: 'Delete Stack Variable'
        },
    ]
}

const CUSTOM_ROLE_STACK_RESTRICTIONS = {
	RESTRICT_READ_TO_OWN_STACKS: 'restrictReadToOwnStacks',
	RESTRICT_WRITE_TO_OWN_STACKS: 'restrictWriteToOwnStacks'
}

export const CUSTOM_ROLE_STACK_RESTRICTIONS_LIST = [
	{
		label: 'No Restrictions',
		value: undefined
	},
	{
		label: 'Restrict read operations to own stacks',
		value: CUSTOM_ROLE_STACK_RESTRICTIONS.RESTRICT_READ_TO_OWN_STACKS
	},
	{
		label: 'Restrict write operations to own stacks',
		value: CUSTOM_ROLE_STACK_RESTRICTIONS.RESTRICT_WRITE_TO_OWN_STACKS
	}
];

export const CONTROL_POLICIES_MAPPING_TYPES = {
	STACK: 'stack',
	NAMESPACE: 'namespace'
};

export const CONTROL_POLICIES_TARGETS_ALL_NAMESPACES_OPTION = {
	id: 'ALL',
	name: 'All Namespaces'
};

export const ALL_ACCOUNTS_OPTION = {
	ALL: 'All',
	ALL_ACCOUNTS: 'All Accounts'
};

export const GENERIC_TABLE_ITEMS_PAGE_SIZE = 10;
export const MODAL_TABLE_ITEMS_PAGE_SIZE = 8;
export const STACKSET_TABLE_ITEMS_PAGE_SIZE = 100;

export const EVENT_RULE_TYPES = {
	CONSOLE_ALLOWED: 'consoleAllowed'
};

// To be added more in the future
export const CONTROL_POLICIES_SECURITY_STANDARTS = [
	{
		id: 'cis aws v1.4',
		name: 'CIS AWS v1.4.0'
	},
	{
		id: 'pci dss v3.2.1',
		name: 'PCI DSS v3.2.1'
	},
	{
		id: 'nist 800-53 rev.5',
		name: 'NIST 800-53 Rev.5'
	}
];

export const CONTROL_POLICIES_MAPPING_KEY = {
	CONTROL_POLICY_ID: 'controlPolicyId',
	CONTROL_POLICY_GROUP_ID: 'controlPolicyGroupId'
};

export const CONTROL_POLICIES_MAPPING_MODAL_ENFORCEMENT_LEVEL_OPTIONS = [
	{
		key: 'warning',
		name: 'Warning',
	},
	{
		key: 'softMandatory',
		name: 'Soft Mandatory'
	},
	{
		key: 'hardMandatory',
		name: 'Hard Mandatory'
	}
];

export const CONTROL_POLICIES_GROUPS_MAPPING_MODAL_ENFORCEMENT_LEVEL_OPTIONS = [
	{
		key: 'bySeverity',
		name: 'By Severity'
	},
	...CONTROL_POLICIES_MAPPING_MODAL_ENFORCEMENT_LEVEL_OPTIONS,
];

export const APPROVAL_POLICY = {
	REQUIRE_APPROVAL: 'requireApproval',
	REQUIRE_TWO_APPROVALS: 'requireTwoApprovals',
	REQUIRE_TEAM_APPROVAL: 'requireTeamsApproval',
	AUTO_APPROVE: 'autoApprove',
};

export const APPROVAL_POLICY_LABELS = {
	[APPROVAL_POLICY.REQUIRE_APPROVAL]: 'Require Approval',
	[APPROVAL_POLICY.REQUIRE_TWO_APPROVALS]: 'Require Two Approvals',
	[APPROVAL_POLICY.REQUIRE_TEAM_APPROVAL]: 'Require Teams Approvals',
	[APPROVAL_POLICY.AUTO_APPROVE]: 'Auto Approve',
}

export const APPROVAL_POLICY_OVERRIDE_BEHAVIOR_OPTIONS = [
	{
		value: 'allow',
		label: 'Allow'
	},
	{
		value: 'deny',
		label: 'Deny'
	},
	{
		value: 'extended',
		label: 'Extended'
	}
];

export const CONFIGURED_STACK = {
	OK: 'ok',
	ERROR: {
		DRIFT_DETECTION_FAILED: 'driftDetectionFailed',
		INITIALIZTION_FAILED: 'initializationFailed',
		BLUEPRINT_INITIALIZTION_FAILED: 'blueprintInitializationFailed',
		STACK_TTL_PASSED: 'stackPassedItsTTL'
	}
};

export const CONFIGURED_STACK_LABELS_REASONS = {
	[CONFIGURED_STACK.ERROR.DRIFT_DETECTION_FAILED]: 'Drift Detection Failed',
	[CONFIGURED_STACK.ERROR.INITIALIZTION_FAILED]: 'Initialization Failed',
	[CONFIGURED_STACK.ERROR.BLUEPRINT_INITIALIZTION_FAILED]: 'Initialization Failed (Persistent Template)',
	[CONFIGURED_STACK.ERROR.STACK_TTL_PASSED]: 'Stack Passed it’s TTL',
};

export const CONFIGURED_STACK_LABELS_TOOLTIP = {
	[CONFIGURED_STACK.ERROR.DRIFT_DETECTION_FAILED]: 'Drift Detection Failed',
	[CONFIGURED_STACK.ERROR.INITIALIZTION_FAILED]: 'Initialization Failed',
	[CONFIGURED_STACK.ERROR.BLUEPRINT_INITIALIZTION_FAILED]: 'Initialization Failed',
};

export const INITIALIZTION_FAILED_GRACE_IN_MINUTES = 10;

export const TF_MODULES_TYPES = {
	LOCAL: 'local',
	GIT: 'git',
	REGISTRY: 'registry'
};

export const TF_MODULES_TYPES_LABELS = {
	[TF_MODULES_TYPES.LOCAL]: 'Local',
	[TF_MODULES_TYPES.GIT]: 'Git',
	[TF_MODULES_TYPES.REGISTRY]: 'Registry'
};

export const TF_MODULES_TYPES_OPTIONS = [
	{
		key: TF_MODULES_TYPES.LOCAL,
		name: TF_MODULES_TYPES_LABELS[TF_MODULES_TYPES.LOCAL]
	},
	{
		key: TF_MODULES_TYPES.GIT,
		name: TF_MODULES_TYPES_LABELS[TF_MODULES_TYPES.GIT]
	},
	{
		key: TF_MODULES_TYPES.REGISTRY,
		name: TF_MODULES_TYPES_LABELS[TF_MODULES_TYPES.REGISTRY]
	},
];

export const TF_MODULES_AND_TF_PROVIDERS_UPDATED_AT_WARNING_IN_HOURS = 25;

export const REPORT_TYPE_OPTIONS = {
    SOC2: {
        key: 'SOC2',
        label: 'SOC2'
    },
    SBOM_TERRAFORM_MODULES: {
        key: 'SBOM_TERRAFORM_MODULES',
        label: 'SBOM Terraform Modules',
    },
	SBOM_TERRAFORM_PROVIDERS: {
        key: 'SBOM_TERRAFORM_PROVIDERS',
        label: 'SBOM Terraform Providers',
    }
}

export const REPORT_YEAR_TO_DATE_VALUE = 'yearToDate';

export const REPORT_TIME_PERIOD_OPTIONS = [
	{
		label: '1 Month',
		daysBack: 30,
	},
	{
		label: '3 Months',
		daysBack: 60, 
	},
	{
		label: '6 Months',
		daysBack: 180,
	},
	{
		label: '12 Months',
		daysBack: 365
	},
	// special option year to to date
	{
		label: 'Year to Date',
		value: REPORT_YEAR_TO_DATE_VALUE,
	}
];

export const RUN_TASK_TYPES = {
	RELEASE_STATE_LOCK: 'releaseStateLock',
	TAINT_RESOURCES: 'taintResources',
	UNTAINT_RESOURCES: 'untaintResources',
	REMOVE_RESOURCES: 'removeResources'
}

export const RUN_TASK_TYPES_LABELS = {
	[RUN_TASK_TYPES.RELEASE_STATE_LOCK]: 'Release State Lock',
	[RUN_TASK_TYPES.TAINT_RESOURCES]: 'Taint Resources',
	[RUN_TASK_TYPES.UNTAINT_RESOURCES]: 'Untaint Resources',
	[RUN_TASK_TYPES.REMOVE_RESOURCES]: 'Remove Resources'
};

export const RUN_TASK_TYPES_LABELS_RUNS_PAGE = {
	[RUN_TASK_TYPES.RELEASE_STATE_LOCK]: 'Release state lock',
	[RUN_TASK_TYPES.TAINT_RESOURCES]: 'Resources to taint',
	[RUN_TASK_TYPES.UNTAINT_RESOURCES]: 'Resources to untaint',
	[RUN_TASK_TYPES.REMOVE_RESOURCES]: 'Resources to remove'
};

export const RUN_TASK_TYPES_OPTIONS = [
	{
		key: RUN_TASK_TYPES.REMOVE_RESOURCES,
		name: RUN_TASK_TYPES_LABELS[RUN_TASK_TYPES.REMOVE_RESOURCES],
	},
	{
		key: RUN_TASK_TYPES.TAINT_RESOURCES,
		name: RUN_TASK_TYPES_LABELS[RUN_TASK_TYPES.TAINT_RESOURCES],
	},
	{	
		key: RUN_TASK_TYPES.UNTAINT_RESOURCES,
		name: RUN_TASK_TYPES_LABELS[RUN_TASK_TYPES.UNTAINT_RESOURCES],
	},
	{
		key: RUN_TASK_TYPES.RELEASE_STATE_LOCK,
		name: RUN_TASK_TYPES_LABELS[RUN_TASK_TYPES.RELEASE_STATE_LOCK],
	}
];

export const GITBOOK_DOCS_URL = 'docs.controlmonkey.io';

export const NODE_TYPES = {
	PROVIDER: "PROVIDER",
    REPO: "REPO",
    FOLDER: "FOLDER",
    STACK: "STACK",
    POTENTIAL_STACK: "POTENTIAL_STACK",
    TEMPLATE: "TEMPLATE",
    // BLUEPRINT: "BLUEPRINT", // blueprints are TEMPLATE node types now
};

export const NODE_TYPES_OPTIONS = [
    { label: 'Stacks', value: NODE_TYPES.STACK },
    { label: 'Potential Stacks', value: NODE_TYPES.POTENTIAL_STACK },
    { label: 'Templates', value: NODE_TYPES.TEMPLATE },
    // { label: 'Blueprints', value: NODE_TYPES.BLUEPRINT }, // blueprints are TEMPLATE node types now
];

export const STACK_DEPENDENCY_TRIGGER = {
	ALWAYS: 'always',
	ON_REFERENCE_VALUE_CHANGE: 'onReferenceValueChange'
};

export const STACK_DEPENDENCY_TRIGGER_OPTIONS = [
	{
		label: 'Always',
		value: STACK_DEPENDENCY_TRIGGER.ALWAYS
	},
	{
		label: 'On Reference Value Change',
		value: STACK_DEPENDENCY_TRIGGER.ON_REFERENCE_VALUE_CHANGE
	}
];

export const STACK_DEPENDENCY_REDACTED_VALUE = 'redacted';

export const TF_MODULES_GIT_SOURCES_PREFIXES = [
	'git::',
	'github.com',
	'git@github.com',
	'bitbucket.org',
	'git@gitlab.com'
];

// here we define the useAppSettings hook localstorage keys
// rule of thumb is to have this setup if we use the hook in more then one place
export const APP_SETTINGS_KEYS = {
	SELECTED_THEME: 'all_pages#theme',
	SELECTED_PROVIDER: 'all_pages#selected_provider',
	// these two examples were united to SELECTED_PROVIDER, left in the code for formatting example
	// SELECTED_DASHBOARD: 'page_dashboard#dashboard_provider_selector#selectedDashboard',
	// SELECTED_IAC_IMPORT_PROVIDER: 'page_iac_import#iac_import_provider_selector#selectedProvider'
}

export const VCS_CONTROL_MONKEY = {
	BOT_TOKEN: '[bot]',
	GITHUB_BOT: 'control-monkey[bot]',
	GITHUB_BOT_PROFILE_URL: 'https://avatars.githubusercontent.com/u/107815524?v=4'
}

export const VCS_PROVIDER_LAST_UPDATED_AT_WARNING_IN_HOURS = 24;
export const ACCOUNT_LOAD_DATA_MESSAGE_RANGE_IN_MINTUES = 20;
export const TF_DATA_MESSAGE_RANGE_IN_MINTUES = 2 * 60;
export const TF_MODULE_VERSION_STATUS_SCAN_TIMOUT_IN_MINTUES = 20;
export const TF_MODULE_SCAN_TIMOUT_IN_MINTUES = 5;

export const GENERATING_TERRAFORM_CODE_MESSAGE = {
	IN_PROGRESS: {
		AWS: 'We are generating more Terraform code for your account',
		AZURE: 'We are generating more Terraform code for your subscription',
		GCP: 'We are generating more Terraform code for your project',
	},
	
	START: {
		AWS: 'Generating Terraform code for your account...',
		AZURE: 'Generating Terraform code for your subscription...',
		GCP: 'Generating Terraform code for your project...',
	},
}

export const COLLECTING_RESOURCES_FOR_ACCOUNT = {
	IN_PROGRESS: {
		AWS: 'Collecting more resources for your account...',
		AZURE: 'Collecting more resources for your subscription...',
		GCP: 'Collecting more resources for your project...',
	},
	
	START: {
		AWS: 'Collecting resources for your account...',
		AZURE: 'Collecting resources for your subscription...',
		GCP: 'Collecting resources for your project...',
	},
}

export const TF_MODULE_STATUS = {
    READY: 'ready',
    NOT_READY: 'notReady',
    READY_NOT_COMPLETED: 'readyNotCompleted'
}

export const TF_MODULE_STATUS_LABELS = {
    [TF_MODULE_STATUS.READY]: "", // no message in this case
    [TF_MODULE_STATUS.NOT_READY]: "Version is not ready yet for Terraform usage",
    [TF_MODULE_STATUS.READY_NOT_COMPLETED]: "Module data is being calculated. It is already available for terraform usage, but some of the data may not be shown here."
}

export const TF_MODULE_NO_VERSION_MESSAGE = "Please wait as we collect and analyze the Terraform Module";

export const MAPPED_TARGETS = {
	CONTROL_POLICIES: {
		NO_TARGETS: "This Control Policy Group has no mapped targets.",
		ENCOURAGE_TO_ADD_TARGETS: 'Enforce cloud compliance by mapping this control policy group to namespaces or stacks using the "Actions" button.',
		ACTIVE_TARGETS: "This Control Policy Group is currently mapped to the following targets:"
	},
	SECURITY_STANDARD: {
		NO_TARGETS: "This Security Standard has no mapped targets.",
		ENCOURAGE_TO_ADD_TARGETS: 'Enforce cloud compliance & security by mapping this security standard to namespaces or stacks using the "Actions" button.',
		ACTIVE_TARGETS: "This Security Standard is currently mapped to the following targets:"
	}
}

export const ENCOURAGE_MAPPED_TARGETS_TEXT = {
	TITLE: "Shift Left Your Cloud Security and Compliance!",
	SUBTITLE: "Map your first control policy and keep your cloud under control.",
	INSTRUCTION: 'Seamlessly map your control policies to stacks or namespaces by clicking the three dots in the action column and selecting "Map Targets".'
}

export const NOTIFICATIONS_EVENT_TYPES_KEY = {
    DRIFT_DETECTED: 'stack::driftDetected',
    WAITING_FOR_APPROVAL: 'stack::deployment::waitingForApproval',
    FAILED_CONTROL_POLICIES_APPROVAL: 'stack::deployment::waitingForFailedControlPoliciesApproval',
    DEPLOYMENT_FAILED: 'stack::deployment::failed',
    APPROVAL_TIMEOUT: 'stack::deployment::approvalTimeout',
    APPLY_STARTED: 'stack::deployment::applyStarted',
    APPLY_FINISHED: 'stack::deployment::applyFinished',
    APPLY_FAILURE: 'stack::deployment::applyFailure'
}

export const NOTIFICATIONS_EVENT_TYPES_LABEL = {
    [NOTIFICATIONS_EVENT_TYPES_KEY.DRIFT_DETECTED]: 'Stack - Drift Detected',
    [NOTIFICATIONS_EVENT_TYPES_KEY.WAITING_FOR_APPROVAL]: 'Stack - Deployment waiting for approval',
    [NOTIFICATIONS_EVENT_TYPES_KEY.FAILED_CONTROL_POLICIES_APPROVAL]: 'Stack - Deployment waiting for failed control policies approval',
    [NOTIFICATIONS_EVENT_TYPES_KEY.DEPLOYMENT_FAILED]: 'Stack - Deployment failed',
    [NOTIFICATIONS_EVENT_TYPES_KEY.APPROVAL_TIMEOUT]: 'Stack - Deployment reached approval timeout',
    [NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_STARTED]: 'Stack - Deployment - Apply started',
    [NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_FINISHED]: 'Stack - Deployment - Apply finished',
    [NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_FAILURE]: 'Stack - Deployment - Apply failure'
}

export const NOTIFICATIONS_EVENT_TYPES_OPTIONS = [
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.DRIFT_DETECTED,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.DRIFT_DETECTED]
	},
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.WAITING_FOR_APPROVAL,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.WAITING_FOR_APPROVAL]
	},
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.FAILED_CONTROL_POLICIES_APPROVAL,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.FAILED_CONTROL_POLICIES_APPROVAL]
	},
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.DEPLOYMENT_FAILED,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.DEPLOYMENT_FAILED]
	},
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.APPROVAL_TIMEOUT,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.APPROVAL_TIMEOUT]
	},
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_STARTED,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_STARTED]
	},
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_FINISHED,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_FINISHED]
	},
	{
		key: NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_FAILURE,
		name: NOTIFICATIONS_EVENT_TYPES_LABEL[NOTIFICATIONS_EVENT_TYPES_KEY.APPLY_FAILURE]
	},
];

export const STACK_AUTO_DISCOVERY = "Stack Auto Discovery"
export const CUSTOM_ABAC_CONFIGURATION = "Custom ABAC Configuration"

export const EVENT_TYPES_KEY = {
    AWS_CONSOLE_OPERATION: "aws::consoleOperation",
}

export const EVENT_TYPES_LABEL = {
    [EVENT_TYPES_KEY.AWS_CONSOLE_OPERATION]: 'AWS Console Operation',
}

export const STACK_WITH_NO_DEPENDENCY_TRIGGERS_OTHER_STACKS_TITLE = "This stack has no dependencies but acts as a source stack for other stacks. "
export const REFER_TO_DEPNDENCY_GRAPH = "see the Dependencies Graph for details."
